/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';
import type { VariableExpression } from './VariableExpression';
import {
    VariableExpressionFromJSON,
    VariableExpressionFromJSONTyped,
    VariableExpressionToJSON,
} from './VariableExpression';

/**
 * 
 * @export
 * @interface Variable
 */
export interface Variable {
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    uuid: string;
    /**
     * 
     * @type {number}
     * @memberof Variable
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    dimAggregation?: VariableDimAggregationEnum;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    timeAggregation?: VariableTimeAggregationEnum;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    modelUuid: string;
    /**
     * 
     * @type {Date}
     * @memberof Variable
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Variable
     */
    createdAt?: Date;
    /**
     * 
     * @type {object}
     * @memberof Variable
     */
    rowStyles?: object;
    /**
     * 
     * @type {number}
     * @memberof Variable
     */
    fillValue?: number;
    /**
     * 
     * @type {Period}
     * @memberof Variable
     */
    period?: Period;
    /**
     * 
     * @type {Array<string>}
     * @memberof Variable
     */
    dimensions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Variable
     */
    inheritedDimensions?: Array<string>;
    /**
     * 
     * @type {Array<VariableExpression>}
     * @memberof Variable
     */
    expressions?: Array<VariableExpression>;
    /**
     * 
     * @type {boolean}
     * @memberof Variable
     */
    isMetric?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Variable
     */
    fillDimValues?: boolean;
}


/**
 * @export
 */
export const VariableDimAggregationEnum = {
    Sum: 'sum',
    Avg: 'avg',
    Count: 'count',
    Min: 'min',
    Max: 'max',
    Formula: 'formula',
    Null: 'null'
} as const;
export type VariableDimAggregationEnum = typeof VariableDimAggregationEnum[keyof typeof VariableDimAggregationEnum];

/**
 * @export
 */
export const VariableTimeAggregationEnum = {
    Sum: 'sum',
    Avg: 'avg',
    First: 'first',
    Last: 'last',
    Count: 'count',
    Min: 'min',
    Max: 'max',
    Formula: 'formula',
    Cumsum: 'cumsum',
    CumsumYear: 'cumsum_year',
    CumsumQuarter: 'cumsum_quarter',
    Null: 'null'
} as const;
export type VariableTimeAggregationEnum = typeof VariableTimeAggregationEnum[keyof typeof VariableTimeAggregationEnum];


/**
 * Check if a given object implements the Variable interface.
 */
export function instanceOfVariable(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('modelUuid' in value)) return false;
    return true;
}

export function VariableFromJSON(json: any): Variable {
    return VariableFromJSONTyped(json, false);
}

export function VariableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Variable {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'orderId': json['order_id'] == null ? undefined : json['order_id'],
        'name': json['name'],
        'unit': json['unit'] == null ? undefined : json['unit'],
        'dimAggregation': json['dim_aggregation'] == null ? undefined : json['dim_aggregation'],
        'timeAggregation': json['time_aggregation'] == null ? undefined : json['time_aggregation'],
        'modelUuid': json['model_uuid'],
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'rowStyles': json['row_styles'] == null ? undefined : json['row_styles'],
        'fillValue': json['fill_value'] == null ? undefined : json['fill_value'],
        'period': json['period'] == null ? undefined : PeriodFromJSON(json['period']),
        'dimensions': json['dimensions'] == null ? undefined : json['dimensions'],
        'inheritedDimensions': json['inherited_dimensions'] == null ? undefined : json['inherited_dimensions'],
        'expressions': json['expressions'] == null ? undefined : ((json['expressions'] as Array<any>).map(VariableExpressionFromJSON)),
        'isMetric': json['is_metric'] == null ? undefined : json['is_metric'],
        'fillDimValues': json['fill_dim_values'] == null ? undefined : json['fill_dim_values'],
    };
}

export function VariableToJSON(value?: Variable | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'order_id': value['orderId'],
        'name': value['name'],
        'unit': value['unit'],
        'dim_aggregation': value['dimAggregation'],
        'time_aggregation': value['timeAggregation'],
        'model_uuid': value['modelUuid'],
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt'] as any).toISOString()),
        'row_styles': value['rowStyles'],
        'fill_value': value['fillValue'],
        'period': PeriodToJSON(value['period']),
        'dimensions': value['dimensions'],
        'inherited_dimensions': value['inheritedDimensions'],
        'expressions': value['expressions'] == null ? undefined : ((value['expressions'] as Array<any>).map(VariableExpressionToJSON)),
        'is_metric': value['isMetric'],
        'fill_dim_values': value['fillDimValues'],
    };
}

