/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddStreamOwnersParams,
  BaseConnector,
  ConnectionStream,
  ConnectionUpdateParams,
  CreateConnectionParams,
  DownloadCSVParams,
  ExecutionResult,
  ExtendedConnection,
  FavoriteConnectionStreamParams,
  FetchConnectionDfParams,
  HTTPValidationError,
  MergeLinkResponse,
  OverviewTableData,
  RemoveConnectionStreamOwnerParams,
  UpdateConnectionStreamParams,
  UpdateSchemaParams,
} from '../models/index';
import {
    AddStreamOwnersParamsFromJSON,
    AddStreamOwnersParamsToJSON,
    BaseConnectorFromJSON,
    BaseConnectorToJSON,
    ConnectionStreamFromJSON,
    ConnectionStreamToJSON,
    ConnectionUpdateParamsFromJSON,
    ConnectionUpdateParamsToJSON,
    CreateConnectionParamsFromJSON,
    CreateConnectionParamsToJSON,
    DownloadCSVParamsFromJSON,
    DownloadCSVParamsToJSON,
    ExecutionResultFromJSON,
    ExecutionResultToJSON,
    ExtendedConnectionFromJSON,
    ExtendedConnectionToJSON,
    FavoriteConnectionStreamParamsFromJSON,
    FavoriteConnectionStreamParamsToJSON,
    FetchConnectionDfParamsFromJSON,
    FetchConnectionDfParamsToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    MergeLinkResponseFromJSON,
    MergeLinkResponseToJSON,
    OverviewTableDataFromJSON,
    OverviewTableDataToJSON,
    RemoveConnectionStreamOwnerParamsFromJSON,
    RemoveConnectionStreamOwnerParamsToJSON,
    UpdateConnectionStreamParamsFromJSON,
    UpdateConnectionStreamParamsToJSON,
    UpdateSchemaParamsFromJSON,
    UpdateSchemaParamsToJSON,
} from '../models/index';

export interface AddConnectionStreamOwnersRequest {
    streamUuid: string;
    workspaceCode: string;
    addStreamOwnersParams: AddStreamOwnersParams;
}

export interface ConnectionURLRequest {
    connectionUuid: string;
    workspaceCode: string;
}

export interface CreateConnectionRequest {
    connectorUuid: string;
    workspaceCode: string;
    createConnectionParams: CreateConnectionParams;
}

export interface CreateConnectionLinkRequest {
    workspaceCode: string;
    type?: CreateConnectionLinkTypeEnum;
    connectionUuid?: string;
}

export interface DownloadStreamAsCsvRequest {
    streamUuid: string;
    workspaceCode: string;
    downloadCSVParams: DownloadCSVParams;
}

export interface FavoriteConnectionStreamRequest {
    connectionStreamUuid: string;
    workspaceCode: string;
    favoriteConnectionStreamParams: FavoriteConnectionStreamParams;
}

export interface FetchConnectionDfRequest {
    streamUuid: string;
    workspaceCode: string;
    fetchConnectionDfParams: FetchConnectionDfParams;
}

export interface GetConnectionStatusRequest {
    connectionUuid: string;
    workspaceCode: string;
}

export interface GetStreamStatusRequest {
    streamUuid: string;
    workspaceCode: string;
}

export interface IsConnectionRunningRequest {
    connectionUuid: string;
    workspaceCode: string;
}

export interface ListConnectionsRequest {
    workspaceCode: string;
}

export interface ListConnectorsRequest {
    workspaceCode: string;
}

export interface RemoveConnectionRequest {
    connectionUuid: string;
    workspaceCode: string;
}

export interface RemoveConnectionStreamOwnerRequest {
    streamUuid: string;
    workspaceCode: string;
    removeConnectionStreamOwnerParams: RemoveConnectionStreamOwnerParams;
}

export interface ResetConnectionRequest {
    connectionUuid: string;
    workspaceCode: string;
}

export interface RunConnectionRequest {
    connectionUuid: string;
    workspaceCode: string;
}

export interface RunStreamRequest {
    streamUuid: string;
    workspaceCode: string;
}

export interface UpdateConnectionDetailsRequest {
    workspaceCode: string;
    connectionUpdateParams: ConnectionUpdateParams;
}

export interface UpdateConnectionStreamDetailsRequest {
    connectionStreamUuid: string;
    workspaceCode: string;
    updateConnectionStreamParams: UpdateConnectionStreamParams;
}

export interface UpdateConnectorSchemaRequest {
    streamId: string;
    workspaceCode: string;
    updateSchemaParams: UpdateSchemaParams;
}

/**
 * 
 */
export class ConnectorsApi extends runtime.BaseAPI {

    /**
     * Add Stream Owners
     */
    async addConnectionStreamOwnersRaw(requestParameters: AddConnectionStreamOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectionStream>> {
        if (requestParameters['streamUuid'] == null) {
            throw new runtime.RequiredError(
                'streamUuid',
                'Required parameter "streamUuid" was null or undefined when calling addConnectionStreamOwners().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling addConnectionStreamOwners().'
            );
        }

        if (requestParameters['addStreamOwnersParams'] == null) {
            throw new runtime.RequiredError(
                'addStreamOwnersParams',
                'Required parameter "addStreamOwnersParams" was null or undefined when calling addConnectionStreamOwners().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/{stream_uuid}/owners`.replace(`{${"stream_uuid"}}`, encodeURIComponent(String(requestParameters['streamUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddStreamOwnersParamsToJSON(requestParameters['addStreamOwnersParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectionStreamFromJSON(jsonValue));
    }

    /**
     * Add Stream Owners
     */
    async addConnectionStreamOwners(requestParameters: AddConnectionStreamOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectionStream> {
        const response = await this.addConnectionStreamOwnersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Connection Url
     */
    async connectionURLRaw(requestParameters: ConnectionURLRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['connectionUuid'] == null) {
            throw new runtime.RequiredError(
                'connectionUuid',
                'Required parameter "connectionUuid" was null or undefined when calling connectionURL().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling connectionURL().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/{connection_uuid}/external_link`.replace(`{${"connection_uuid"}}`, encodeURIComponent(String(requestParameters['connectionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Connection Url
     */
    async connectionURL(requestParameters: ConnectionURLRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.connectionURLRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Connection Create
     */
    async createConnectionRaw(requestParameters: CreateConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['connectorUuid'] == null) {
            throw new runtime.RequiredError(
                'connectorUuid',
                'Required parameter "connectorUuid" was null or undefined when calling createConnection().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createConnection().'
            );
        }

        if (requestParameters['createConnectionParams'] == null) {
            throw new runtime.RequiredError(
                'createConnectionParams',
                'Required parameter "createConnectionParams" was null or undefined when calling createConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/{connector_uuid}/connect`.replace(`{${"connector_uuid"}}`, encodeURIComponent(String(requestParameters['connectorUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateConnectionParamsToJSON(requestParameters['createConnectionParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Connection Create
     */
    async createConnection(requestParameters: CreateConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Connection Link Token
     */
    async createConnectionLinkRaw(requestParameters: CreateConnectionLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MergeLinkResponse>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createConnectionLink().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['connectionUuid'] != null) {
            queryParameters['connection_uuid'] = requestParameters['connectionUuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/link`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MergeLinkResponseFromJSON(jsonValue));
    }

    /**
     * Create Connection Link Token
     */
    async createConnectionLink(requestParameters: CreateConnectionLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MergeLinkResponse> {
        const response = await this.createConnectionLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download Stream As Csv
     */
    async downloadStreamAsCsvRaw(requestParameters: DownloadStreamAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['streamUuid'] == null) {
            throw new runtime.RequiredError(
                'streamUuid',
                'Required parameter "streamUuid" was null or undefined when calling downloadStreamAsCsv().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling downloadStreamAsCsv().'
            );
        }

        if (requestParameters['downloadCSVParams'] == null) {
            throw new runtime.RequiredError(
                'downloadCSVParams',
                'Required parameter "downloadCSVParams" was null or undefined when calling downloadStreamAsCsv().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/streams/{stream_uuid}/csv`.replace(`{${"stream_uuid"}}`, encodeURIComponent(String(requestParameters['streamUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DownloadCSVParamsToJSON(requestParameters['downloadCSVParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download Stream As Csv
     */
    async downloadStreamAsCsv(requestParameters: DownloadStreamAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.downloadStreamAsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Favorite Connection Stream
     */
    async favoriteConnectionStreamRaw(requestParameters: FavoriteConnectionStreamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['connectionStreamUuid'] == null) {
            throw new runtime.RequiredError(
                'connectionStreamUuid',
                'Required parameter "connectionStreamUuid" was null or undefined when calling favoriteConnectionStream().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling favoriteConnectionStream().'
            );
        }

        if (requestParameters['favoriteConnectionStreamParams'] == null) {
            throw new runtime.RequiredError(
                'favoriteConnectionStreamParams',
                'Required parameter "favoriteConnectionStreamParams" was null or undefined when calling favoriteConnectionStream().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/connections/{connection_stream_uuid}/favorite`.replace(`{${"connection_stream_uuid"}}`, encodeURIComponent(String(requestParameters['connectionStreamUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteConnectionStreamParamsToJSON(requestParameters['favoriteConnectionStreamParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Favorite Connection Stream
     */
    async favoriteConnectionStream(requestParameters: FavoriteConnectionStreamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.favoriteConnectionStreamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Connection Df
     */
    async fetchConnectionDfRaw(requestParameters: FetchConnectionDfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OverviewTableData>> {
        if (requestParameters['streamUuid'] == null) {
            throw new runtime.RequiredError(
                'streamUuid',
                'Required parameter "streamUuid" was null or undefined when calling fetchConnectionDf().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchConnectionDf().'
            );
        }

        if (requestParameters['fetchConnectionDfParams'] == null) {
            throw new runtime.RequiredError(
                'fetchConnectionDfParams',
                'Required parameter "fetchConnectionDfParams" was null or undefined when calling fetchConnectionDf().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/connections/{stream_uuid}/df`.replace(`{${"stream_uuid"}}`, encodeURIComponent(String(requestParameters['streamUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchConnectionDfParamsToJSON(requestParameters['fetchConnectionDfParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OverviewTableDataFromJSON(jsonValue));
    }

    /**
     * Fetch Connection Df
     */
    async fetchConnectionDf(requestParameters: FetchConnectionDfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OverviewTableData> {
        const response = await this.fetchConnectionDfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Connection Status
     */
    async getConnectionStatusRaw(requestParameters: GetConnectionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['connectionUuid'] == null) {
            throw new runtime.RequiredError(
                'connectionUuid',
                'Required parameter "connectionUuid" was null or undefined when calling getConnectionStatus().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling getConnectionStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/connections/{connection_uuid}/status`.replace(`{${"connection_uuid"}}`, encodeURIComponent(String(requestParameters['connectionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Connection Status
     */
    async getConnectionStatus(requestParameters: GetConnectionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getConnectionStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Connection Stream Status
     */
    async getStreamStatusRaw(requestParameters: GetStreamStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['streamUuid'] == null) {
            throw new runtime.RequiredError(
                'streamUuid',
                'Required parameter "streamUuid" was null or undefined when calling getStreamStatus().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling getStreamStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/connections/streams/{stream_uuid}/status`.replace(`{${"stream_uuid"}}`, encodeURIComponent(String(requestParameters['streamUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Connection Stream Status
     */
    async getStreamStatus(requestParameters: GetStreamStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getStreamStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Is Connection Running
     */
    async isConnectionRunningRaw(requestParameters: IsConnectionRunningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['connectionUuid'] == null) {
            throw new runtime.RequiredError(
                'connectionUuid',
                'Required parameter "connectionUuid" was null or undefined when calling isConnectionRunning().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling isConnectionRunning().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/connections/{connection_uuid}/is_running`.replace(`{${"connection_uuid"}}`, encodeURIComponent(String(requestParameters['connectionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Is Connection Running
     */
    async isConnectionRunning(requestParameters: IsConnectionRunningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.isConnectionRunningRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Connections
     */
    async listConnectionsRaw(requestParameters: ListConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExtendedConnection>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling listConnections().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/connections`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExtendedConnectionFromJSON));
    }

    /**
     * List Connections
     */
    async listConnections(requestParameters: ListConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExtendedConnection>> {
        const response = await this.listConnectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Connectors
     */
    async listConnectorsRaw(requestParameters: ListConnectorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BaseConnector>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling listConnectors().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BaseConnectorFromJSON));
    }

    /**
     * List Connectors
     */
    async listConnectors(requestParameters: ListConnectorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BaseConnector>> {
        const response = await this.listConnectorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Connection
     */
    async removeConnectionRaw(requestParameters: RemoveConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['connectionUuid'] == null) {
            throw new runtime.RequiredError(
                'connectionUuid',
                'Required parameter "connectionUuid" was null or undefined when calling removeConnection().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling removeConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/connections/{connection_uuid}/delete`.replace(`{${"connection_uuid"}}`, encodeURIComponent(String(requestParameters['connectionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Connection
     */
    async removeConnection(requestParameters: RemoveConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.removeConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove Stream Owner
     */
    async removeConnectionStreamOwnerRaw(requestParameters: RemoveConnectionStreamOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectionStream>> {
        if (requestParameters['streamUuid'] == null) {
            throw new runtime.RequiredError(
                'streamUuid',
                'Required parameter "streamUuid" was null or undefined when calling removeConnectionStreamOwner().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling removeConnectionStreamOwner().'
            );
        }

        if (requestParameters['removeConnectionStreamOwnerParams'] == null) {
            throw new runtime.RequiredError(
                'removeConnectionStreamOwnerParams',
                'Required parameter "removeConnectionStreamOwnerParams" was null or undefined when calling removeConnectionStreamOwner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/{stream_uuid}/owners/remove`.replace(`{${"stream_uuid"}}`, encodeURIComponent(String(requestParameters['streamUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveConnectionStreamOwnerParamsToJSON(requestParameters['removeConnectionStreamOwnerParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectionStreamFromJSON(jsonValue));
    }

    /**
     * Remove Stream Owner
     */
    async removeConnectionStreamOwner(requestParameters: RemoveConnectionStreamOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectionStream> {
        const response = await this.removeConnectionStreamOwnerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset Connection
     */
    async resetConnectionRaw(requestParameters: ResetConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['connectionUuid'] == null) {
            throw new runtime.RequiredError(
                'connectionUuid',
                'Required parameter "connectionUuid" was null or undefined when calling resetConnection().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling resetConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/connections/{connection_uuid}/reset`.replace(`{${"connection_uuid"}}`, encodeURIComponent(String(requestParameters['connectionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Reset Connection
     */
    async resetConnection(requestParameters: ResetConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.resetConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run Connection
     */
    async runConnectionRaw(requestParameters: RunConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecutionResult>> {
        if (requestParameters['connectionUuid'] == null) {
            throw new runtime.RequiredError(
                'connectionUuid',
                'Required parameter "connectionUuid" was null or undefined when calling runConnection().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling runConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/connections/{connection_uuid}/run`.replace(`{${"connection_uuid"}}`, encodeURIComponent(String(requestParameters['connectionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecutionResultFromJSON(jsonValue));
    }

    /**
     * Run Connection
     */
    async runConnection(requestParameters: RunConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecutionResult> {
        const response = await this.runConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run Stream
     */
    async runStreamRaw(requestParameters: RunStreamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecutionResult>> {
        if (requestParameters['streamUuid'] == null) {
            throw new runtime.RequiredError(
                'streamUuid',
                'Required parameter "streamUuid" was null or undefined when calling runStream().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling runStream().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/connections/streams/{stream_uuid}/run`.replace(`{${"stream_uuid"}}`, encodeURIComponent(String(requestParameters['streamUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecutionResultFromJSON(jsonValue));
    }

    /**
     * Run Stream
     */
    async runStream(requestParameters: RunStreamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecutionResult> {
        const response = await this.runStreamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Connection
     */
    async updateConnectionDetailsRaw(requestParameters: UpdateConnectionDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateConnectionDetails().'
            );
        }

        if (requestParameters['connectionUpdateParams'] == null) {
            throw new runtime.RequiredError(
                'connectionUpdateParams',
                'Required parameter "connectionUpdateParams" was null or undefined when calling updateConnectionDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/connections/update`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectionUpdateParamsToJSON(requestParameters['connectionUpdateParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Connection
     */
    async updateConnectionDetails(requestParameters: UpdateConnectionDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateConnectionDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Connection Stream
     */
    async updateConnectionStreamDetailsRaw(requestParameters: UpdateConnectionStreamDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['connectionStreamUuid'] == null) {
            throw new runtime.RequiredError(
                'connectionStreamUuid',
                'Required parameter "connectionStreamUuid" was null or undefined when calling updateConnectionStreamDetails().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateConnectionStreamDetails().'
            );
        }

        if (requestParameters['updateConnectionStreamParams'] == null) {
            throw new runtime.RequiredError(
                'updateConnectionStreamParams',
                'Required parameter "updateConnectionStreamParams" was null or undefined when calling updateConnectionStreamDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/streams/{connection_stream_uuid}/update`.replace(`{${"connection_stream_uuid"}}`, encodeURIComponent(String(requestParameters['connectionStreamUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateConnectionStreamParamsToJSON(requestParameters['updateConnectionStreamParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Connection Stream
     */
    async updateConnectionStreamDetails(requestParameters: UpdateConnectionStreamDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateConnectionStreamDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the schema associated to a specific connection stream
     * Update Connector Schema
     */
    async updateConnectorSchemaRaw(requestParameters: UpdateConnectorSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['streamId'] == null) {
            throw new runtime.RequiredError(
                'streamId',
                'Required parameter "streamId" was null or undefined when calling updateConnectorSchema().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateConnectorSchema().'
            );
        }

        if (requestParameters['updateSchemaParams'] == null) {
            throw new runtime.RequiredError(
                'updateSchemaParams',
                'Required parameter "updateSchemaParams" was null or undefined when calling updateConnectorSchema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/connectors/streams/{stream_id}/schema`.replace(`{${"stream_id"}}`, encodeURIComponent(String(requestParameters['streamId']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSchemaParamsToJSON(requestParameters['updateSchemaParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates the schema associated to a specific connection stream
     * Update Connector Schema
     */
    async updateConnectorSchema(requestParameters: UpdateConnectorSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateConnectorSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CreateConnectionLinkTypeEnum = {
    Custom: 'custom',
    Hris: 'hris',
    Accounting: 'accounting',
    Ats: 'ats',
    Crm: 'crm',
    Ticketing: 'ticketing'
} as const;
export type CreateConnectionLinkTypeEnum = typeof CreateConnectionLinkTypeEnum[keyof typeof CreateConnectionLinkTypeEnum];
