import { useLocalStorage } from '@vueuse/core';
import {
        AuthApi,
        AdminApi,
        BaseConnector,
        BatchProcessTask,
        Comment,
        Configuration,
        ConnectionStream,
        ConnectionStreamOwner,
        ConnectorMetric,
        ConnectorsApi,
        CreateScenario,
        Credential,
        CredentialTypeEnum,
        DeleteCredentialParamsTypeEnum,
        DimensionsApi,
        Discussion,
        DiscussionsApi,
        ExtendedConnection,
        Filter,
        FilterBooleanEnum,
        FilterOperationEnum,
        Invite,
        Metric,
        MetricCategory,
        MetricOwner,
        MetricsApi,
        MetricUnit,
        MetricUnitEnum,
        NavApi,
        Period,
        PiechartData,
        RegisterParams,
        RoleSwitchParamsRoleEnum,
        ScenarioTreeNode,
        ScenariosApi,
        SchemaColumn,
        SourcesData,
        SupportApi,
        SwitchCredentialParamsTypeEnum,
        UploadDetails,
        Uploader,
        UploaderOwner,
        UploadersApi,
        User,
        Workspace,
        WorkspaceApi,
        CreateConnectionLinkTypeEnum,
        BaseConnectorTypeEnum,
        Table,
        RegistrationRequestParams,
        CreateConnectionParams,
        AuthUser,
        WorkspaceMembershipRoleEnum,
        TableAttributes,
        NavSection,
        Group,
        Mapping,
        WorkspaceDateFormatEnum,
        UpdateTableAttributesParams,
        MetricGranularityEnum,
        Model,
        ModelsApi,
        UpdateModelVariableParams,
        UpdateModelParams,
        UpdateModelPeriodParams,
        Variable,
        DrillDownParams,
        SearchApi,
        GroupTypeEnum,
        UpdateModelPeriodGrainParams,
        ModelContributorRoleEnum,
        UpdateModelVersionContributorsParams,
        UpdateCategoryParams,
        PublicApi,
        UpdateModelVersionParams,
        NotifyModelVersionExcelDownloadParams,
        CommunityAsset,
        CommunityApi,
        PublishModelToCommunityParams,
        UpdateAssetDetailsParams,
        Middleware,
        Database,
        DatabasesApi,
        UpdateDatabaseParams,
        OverviewTableData,
        FetchDatabaseParams,
        UpdateDatabaseSchemaParams,
        UpdateDatabaseRelationshipsParams,
        VariableDrillDownParams,
        SerializedQuery,
        UpdateScenario,
        VariableExpression,
        DagApi,
        AiApi,
        DimensionValue,
        AddExpressionToVariableParams,
        SnapshotsApi,
        Snapshot,
        SnapshotOwner,
        SourcesChartNode,
        DashboardComparativeTableData,
        DashboardsApi,
        Dashboard,
        DashboardTableData,
        DashboardContributor,
        UpdateDashboardParams,
        SharedTable,
        UpdateSharedTableDetailsParams,
        CreateDatabaseParamsSourceTypeEnum,
        SharedTableOwner,
        SimplifiedTable,
        ImportTableRemapParams,
} from '~/gen';
import { defineStore, Store } from 'pinia';
import { Dimension } from '~/gen/models/Dimension';
import { Item } from '~/components/MultiSelect/types';
import { UICommentsBlock, UIDiscussion } from '~/components/Discussion/types';
import { DateTime, Settings } from "luxon";
import _, { camelCase } from "lodash";
import usePreferences from './usePreferences';
import { useDownload } from './useDownload';
import { storedPeriod } from './useTableValues';
import { useConfig } from './useConfig';
import router from "~/lib/router";
import { nextTick, onUnmounted, ref, Ref, toRaw, watch } from 'vue';
import { useProvider } from './useCollab';
import { Doc } from 'yjs';
import { TiptapTransformer } from "@hocuspocus/transformer";
import { getRating } from './useRating';
import { formatDate, jsDateToCorrectUtcDate } from '~/lib/localize';
import { Status } from '~/components/DatasourceStatus/types';
import { UpdateDatabaseOwnersParams } from '~/gen/models/UpdateDatabaseOwnersParams';
import { defaultRedirect } from './useRedirect';
import { getRole } from '~/lib/permissions';
import { Item as SidebarMenuItem } from "~/components/SidebarMenu/types"
import useChat from './useChat';

const cfg = useConfig();

export const DEFAULT_GRANULARITY = "month";
type OptionalWorkspace = Workspace | undefined;

const workspaceCode = useLocalStorage('workspace', '');
const config = new Configuration({
        basePath: cfg.baseURL,
        credentials: 'include',
        middleware: [{
                post: async (context) => {
                        if (context.response.status == 401 && router.currentRoute.value.name != 'login' && router.currentRoute.value.path != '/') {
                                router.push({ name: 'login', query: { redirect: router.currentRoute.value.fullPath } });
                        }
                }
        } as Middleware
        ]
});
const authApi = new AuthApi(config);
const connApi = new ConnectorsApi(config);
const wsApi = new WorkspaceApi(config);
const metricsApi = new MetricsApi(config);
const navApi = new NavApi(config);
const dashboardsApi = new DashboardsApi(config);
const scenariosApi = new ScenariosApi(config);
const dimensionsApi = new DimensionsApi(config);
const uploadersApi = new UploadersApi(config);
const supportApi = new SupportApi(config);
const discussionsApi = new DiscussionsApi(config);
const modelsApi = new ModelsApi(config);
const searchApi = new SearchApi(config);
const publicApi = new PublicApi(config);
const communityApi = new CommunityApi(config);
const databasesApi = new DatabasesApi(config);
const adminApi = new AdminApi(config);
const dagApi = new DagApi(config);
const aiApi = new AiApi(config);
const snapshotsApi = new SnapshotsApi(config);


export const useAuthApi = defineStore('api/auth', {
        state: () => {
                return {
                        loggedIn: false,
                        credentials: [] as Credential[],
                        user: undefined as User | undefined
                };
        },
        getters: {
                linkedCredentials: ({ credentials }) => credentials.filter(({ type }) => ['cofi_email', 'auth0_email', 'auth_google'].includes(type)),
                credentialsEmails: ({ credentials }) => credentials.filter(({ type }) => type === 'cofi_email'),
                tokens: ({ credentials }) => credentials.filter(c => c.type == "token")
        },
        actions: {
                async disableCredential(key: string, type: CredentialTypeEnum) {
                        const castType = type as unknown as SwitchCredentialParamsTypeEnum; // FIXME: duplicated types "SwitchCredentialParamsTypeEnum" & "CredentialTypeEnum"
                        await authApi.disableCredential({ switchCredentialParams: { key, type: castType, enabled: false } });
                        this.credentials = this.credentials.map(
                                cred => cred.type === type && cred.key == key ? { ...cred, enabled: false } : cred
                        );
                },
                async enableCredential(key: string, type: CredentialTypeEnum) {
                        const castType = type as unknown as SwitchCredentialParamsTypeEnum; // FIXME: duplicated types "SwitchCredentialParamsTypeEnum" & "CredentialTypeEnum"
                        await authApi.enableCredential({ switchCredentialParams: { key, type: castType, enabled: true } });
                        this.credentials = this.credentials.map(
                                cred => cred.type === type && cred.key == key ? { ...cred, enabled: true } : cred
                        );
                },
                async revokeCredentials(key: string, type: DeleteCredentialParamsTypeEnum) {
                        await authApi.removeCredential({
                                deleteCredentialParams: { key, type }
                        });
                },
                async updateAvatar(file: Blob) {
                        await authApi.updateUserAvatar({ file });
                },
                async updateUser(updates: Record<string, string>) {
                        await authApi.updateUserProfile({
                                updateUserProfileParams: { updates }
                        });
                },
                async fetchCredentials() {
                        this.credentials = await authApi.credentials();
                },
                async check() {
                        try {
                                this.user = await authApi.getUser();
                                this.loggedIn = true;
                                return true;
                        } catch (err) {
                                return false;
                        }
                },
                async login(email: string, password: string) {
                        try {
                                await authApi.authenticate({
                                        loginParams: { email, password }
                                });
                                this.loggedIn = true;
                                return true;
                        } catch (err) {
                                return false;
                        }
                },
                async getGoogleUserDetails() {
                        const oauthEndpoint = `${cfg.baseURL}/auth/oauth/login`;

                        const code = await new Promise<string>((resolve) => {
                                const popup = window.open(oauthEndpoint) as Window;
                                const onTokenReceived = ({ origin, data }: MessageEvent) => {
                                        if (origin !== cfg.baseURL) {
                                                console.log("unknown message, skipping");
                                                return;
                                        }
                                        const { code } = data;
                                        window.removeEventListener("message", onTokenReceived, false);
                                        popup.close();
                                        resolve(code);
                                };
                                window.addEventListener("message", onTokenReceived, false);
                        });

                        if (code) {
                                const details = await authApi.getOAuthUserData({ code });
                                return details;
                        }
                },
                async googleOauthLogin() {
                        const oauthEndpoint = `${cfg.baseURL}/auth/oauth/login`;

                        const code = await new Promise<string>((resolve) => {
                                const popup = window.open(oauthEndpoint) as Window;
                                const onTokenReceived = ({ origin, data }: MessageEvent) => {
                                        if (origin !== cfg.baseURL) {
                                                console.log("unknown message, skipping");
                                                return;
                                        }
                                        const { code } = data;
                                        window.removeEventListener("message", onTokenReceived, false);
                                        popup.close();
                                        resolve(code);
                                };
                                window.addEventListener("message", onTokenReceived, false);
                        });

                        if (code) {
                                await authApi.token({ code }).then(() => {
                                        this.loggedIn = true;
                                });
                        }
                        return this.loggedIn;
                },
                async requestPasswordReset(email: string) {
                        try {
                                await authApi.requestPasswordReset({ requestPasswordResetParams: { email } });
                                return true;
                        } catch (err) {
                                return false;
                        }
                },
                async resetPassword(token: string, password: string) {
                        await authApi.changePassword({ resetPasswordParams: { password, resetToken: token } });
                },
                async logout() {
                        await authApi.logout();
                        resetDataStores();
                        this.loggedIn = false;
                        this.user = undefined;
                },
                async toggleVisibility() {
                        const user = this.user as User;
                        const visible = !user.isHidden;
                        const userUuid = user?.uuid as string;
                        await authApi.setVisibility({ setVisibilityParams: { userUuid, visible } });
                        this.user = { ...user, isHidden: visible };
                },
                async register(params: RegisterParams) {
                        return await authApi.registerUser({ registerParams: params });
                },
                async requestRegister(params: RegistrationRequestParams) {
                        return await authApi.registrationRequest({ registrationRequestParams: params });
                },
                async googleRegister() {
                        return new Promise((resolve, reject) => {
                                const popup = window.open(`${cfg.baseURL}/auth/oauth/register`);
                                const onTokenReceived = ({ origin, data }) => {
                                        if (origin !== cfg.baseURL) {
                                                console.log("unknown message, skipping");
                                                return;
                                        }
                                        if (data.hasOwnProperty('error')) {
                                                reject(data["error"]);
                                        }
                                        window.localStorage.setItem("cofi_token", data["access_token"]);
                                        window.removeEventListener("message", onTokenReceived, false);
                                        popup?.close();
                                        resolve(null);
                                };
                                window.addEventListener("message", onTokenReceived, false);
                        });
                },
                async linkGoogleAccount() {
                        return new Promise((resolve, reject) => {
                                const popup = window.open(`${cfg.baseURL}/auth/link-google`);
                                const onTokenReceived = ({ origin, data }) => {
                                        if (origin !== cfg.baseURL) {
                                                console.log("unknown message, skipping");
                                                return;
                                        }
                                        if (data.hasOwnProperty('error')) {
                                                reject(data["error"]);
                                        }
                                        window.removeEventListener("message", onTokenReceived, false);
                                        popup?.close();
                                        resolve(data["email"]);
                                };
                                window.addEventListener("message", onTokenReceived, false);
                        });
                },
                async requestToken(name: string, description?: string, expiresOn?: number) {
                        const token = await authApi.apiAccessToken({ requestTokenParameters: { name, description, expiresOn } });
                        this.credentials = [...this.credentials,
                        {
                                type: "token",
                                userUuid: this.user.uuid,
                                enabled: true, name,
                                key: token.split(':')[0],
                                description,
                                expiresOn: expiresOn ? DateTime.now().plus({ days: expiresOn }).toJSDate() : null
                        } as Credential
                        ];
                        return token;
                }
        }
});

export const useWorkspaceApi = defineStore('api/workspaces', {
        state: () => ({
                _current: undefined as OptionalWorkspace,
                workspaces: [] as Workspace[],
                users: [] as AuthUser[]
        }),
        getters: {
                hasUser: ({ users }) => (userId: string) => users.some(x => x.uuid === userId),
                getUser: ({ users }) => {
                        // Creating the map here before the return 
                        // function caches it, per how Pinia works internally.
                        const usersMap = users.reduce((a, c) => ({ ...a, [c.uuid]: c }), {} as Record<string, User>);
                        return (userId: string) => usersMap[userId];
                },
                getUserRole: ({ users, _current }) => (userId: string) => {
                        const user = users.find(x => x.uuid === userId);
                        return user?.memberships?.find(m => m.workspaceUuid === _current?.uuid)?.role;
                },
                sortedWorkspaces: ({ workspaces }) => {
                        return workspaces.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLowerCase()));
                },
                getWorkspacesMap: ({workspaces}) => workspaces.reduce((a,c) => ({...a, [c.uuid]: c}), {} as Record<string, Workspace>) 
        },
        actions: {
                async current(overrideCode?: string): Promise<Workspace> {
                        const routerCode = overrideCode || router?.currentRoute?.value?.params?.code;
                        if (this._current) {
                                if (routerCode === this._current.code) return this._current;
                                const ws = this.workspaces.find(w => w.code === routerCode);
                                if (ws) {
                                        this._current = ws;
                                        return this._current;
                                }
                        }
                        await this.fetchCurrent(overrideCode);
                        return this._current;
                },
                async updateWorkspace(uuid: string, updates: {
                        name?: string,
                        code?: string,
                        logo?: string | null,
                        color?: string | undefined,
                        default_metric_unit?: MetricUnitEnum,
                        fiscal_year_start_month?: number
                        date_format?: WorkspaceDateFormatEnum
                        decimals_separator?: string
                        data_start_date?: Date
                        data_end_date?: Date
                        closing_date?: Date | null
                        default_redirect_entity_uuid?: string | null
                }) {

                        function parseDates(updates: Record<string, any>): Record<string, any> {
                                const attrs = ['closing_date', 'data_start_date', 'data_end_date'];
                                const parsedDates = attrs.reduce((acc, curr) => {
                                        if (updates[curr]) {
                                                acc[curr] = jsDateToCorrectUtcDate(updates[curr]);
                                        }
                                        return acc;
                                }, {} as Record<string, any>);
                                return { ...updates, ...parsedDates };
                        }
                        await wsApi.updateWorkspace({ updateWorkspaceParams: { uuid, updates: parseDates(updates) } });

                        const camelCasedUpdates = Object.entries(updates).reduce(
                                (acc, [k, v]) => ({ ...acc, [camelCase(k)]: v }),
                                {});
                        const updated = { ...toRaw(this._current), ...camelCasedUpdates };
                        this._current = updated;
                        this.workspaces = this.workspaces.map(ws => ws.uuid === uuid ? updated : ws);

                        if (updates.code) {
                                workspaceCode.value = updates.code;
                        }
                },
                async updateWorkspaceLogo(uuid: string, logo: File) {
                        await wsApi.updateWorkspaceLogo({ file: logo, workspaceUuid: uuid });
                },
                async fetchCurrent(overrideCode?: string) {
                        await this.fetchAll();
                        const code = overrideCode || router?.currentRoute?.value?.params?.code || workspaceCode.value || "";
                        if (code !== workspaceCode.value) workspaceCode.value = code as string;
                        if (workspaceCode.value && this.workspaces.some(ws => ws.code === code)) {
                                this._current = this.workspaces.find(ws => ws.code === code);
                                return;
                        }
                        const [workspace] = this.workspaces;
                        workspaceCode.value = workspace.code;
                        this._current = workspace;
                },
                async fetchAll(force = false) {
                        if (this.workspaces.length && !force) return;
                        this.workspaces = await wsApi.fetchUserWorkspaces();
                },
                async fetchUsers(wsCode?: string, force = false) {
                        if (this.users.length && !force) return;
                        const code = (await this.current(wsCode)).code;
                        this.users = await wsApi.fetchWorkspaceUsers({ workspaceCode: code });
                },
                async updateUserRole(userUuid: string, role: RoleSwitchParamsRoleEnum) {
                        const workspaceUuid = (await this.current()).uuid as string;
                        await authApi.assignRole({ roleSwitchParams: { userUuid, workspaceUuid, role } });
                        this.users = this.users?.map(u =>
                                u.uuid === userUuid ? {
                                        ...u,
                                        memberships: u.memberships?.map(m =>
                                                m.workspaceUuid === workspaceUuid ? { ...m, role } : m)
                                } : u
                        );
                },
                async removeUser(user_uuid: string) {
                        const code = (await this.current()).code;
                        await wsApi.removeUserFromWorkspace({ userUuid: user_uuid, workspaceCode: code });
                        this.users = this.users.filter(u => u.uuid != user_uuid);
                },
                async switch(code: string) {
                        await this.fetchAll();
                        if (!this.workspaces.some(ws => ws.code === code)) {
                                throw new Error(`Workspace(${code}) was not found.`);
                        }
                        // we have a guard, so this will return a Workspace for sure
                        this._current = this.workspaces.find(ws => ws.code === code) as Workspace;
                        workspaceCode.value = this._current.code;
                        const redirectRoute = defaultRedirect(this._current);
                        await router.push(redirectRoute);
                },
                async create(name: string, code: string, templateWorkspaceUuid?: string, includeUploads = false) {
                        // TODO: Create a templateOptions object with all the necessary info, instead of multiple params for the same
                        // purpose.
                        const newWorkspace = await wsApi.createWorkspace({ createWorkspaceParams: { name, code, templateWorkspaceUuid, includeUploads } });
                        this.workspaces = [...this.workspaces, newWorkspace];
                },
                setLastAccessedUrl() {
                        const urlSplit = router.currentRoute.value.fullPath.split(this._current.code).slice(-1)[0].split("/") as string[];
                        const sectionTypeMap = {
                                "dashboards": "dashboard",
                                "models": "model"
                        } as Record<string, string>;

                        const type = sectionTypeMap[urlSplit[1]];
                        const code = urlSplit[2];
                        const updated = { ...this._current, lastAccessedEntity: { type, code } };

                        this._current = updated;
                        this.workspaces = this.workspaces.map(w => w.uuid === updated.uuid ? updated : w);
                }
        }
});

export const useInviteApi = defineStore('api/invites', {
        state: () => ({
                invites: [] as Invite[]
        }),
        actions: {
                async fetchInvites() {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current()).code as string;
                        this.invites = await wsApi.fetchWorkspacePendingInvites({ workspaceCode });
                },
                async fetchInvite(id: string) {
                        return await wsApi.fetchNewUserInvite({ inviteUuid: id });
                },
                async invite(email: string, role: WorkspaceMembershipRoleEnum, name: string, lastName: string) {
                        const api = useWorkspaceApi();
                        const workspaceUuid = (await api.current()).uuid as string;
                        await wsApi.inviteUserToWorkspace({
                                inviteUserToWorkspaceParams:
                                        { email, workspaceUuid: workspaceUuid, role, name, lastName }
                        });
                },
                async cancelInvite(inviteUuid: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current()).code as string;
                        await wsApi.cancelInvite({ inviteUuid, workspaceCode });
                        this.invites = this.invites.filter(x => x.uuid !== inviteUuid);
                }
        }
});

export interface NavFolder extends Omit<Group, 'createdByUuid'> {
        createdByUuid?: string // Does not exist in "Uncategorized"
        items: NavSection[]
}

export const closedFolders = useLocalStorage('closedFolders', {} as Record<string, boolean>);

type NavSectionType = "dashboard" | "model" | "datasource" | "metric" | "database";
const linkMap = {
        dashboard: "code-dashboards-id",
        model: "code-models-id",
        datasource: "code-datasources-id",
        metric: "code-metrics-id",
        database: "code-databases-id"
} as Record<NavSectionType, string>;

export const asLink = (r: NavSection | null, type: NavSectionType, overrideLabel?: string) => ({
        ...r,
        type,
        label: overrideLabel || r?.name?.trim() || "Untitled",
        link: {
                name: linkMap[type],
                params: { id: r?.uuid },
                uuid: `${type}-${r?.name?.trim()}`
        }
});
const asFolder = (uuid: string, name: string, color?: string): Partial<NavFolder> => ({
        name,
        label: name,
        uuid,
        items: [],
        type: 'folder',
        color
});

export const useNavApi = defineStore('api/navigation', {
        state: () => ({
                dashboardsFolders: [] as NavFolder[],
                dashboards: [] as NavSection[],
                favoriteDashboards: [] as NavSection[],
                models: [] as NavSection[],
                modelsFolders: [] as NavFolder[],
                databases: [] as NavSection[],
                databasesFolders: [] as NavFolder[],
                favoriteModels: [] as NavSection[],
                datasourcesFolders: [] as NavFolder[],
                favoriteDatasources: [] as NavFolder[],
                favoritesUuids: [] as string[]
        }),
        getters: {
                getIsFavorited: ({ favoritesUuids }) => (uuid: string) => favoritesUuids.some(i => i === uuid),
                sortedDashboardsFolders: ({ dashboards, favoriteDashboards, dashboardsFolders }): NavFolder[] => {
                        const sortedFavoritedDashboards = favoriteDashboards.sort((a, b) => a.name.localeCompare(b.name));
                        const sortedDashboards = [...favoriteDashboards, ...dashboards].sort((a, b) => a.name.localeCompare(b.name));

                        const favoritedFolder: NavFolder = {
                                uuid: "favorites",
                                name: "My Favorites",
                                color: "yellow",
                                workspaceUuid: useWorkspaceApi()._current?.uuid as string,
                                items: sortedFavoritedDashboards,
                                type: 'dashboard'
                        };
                        const user = useAuthApi().user;

                        const foldersMap: Record<string, NavFolder> = dashboardsFolders
                                .sort((a, b) => a.name === 'uncategorized' ? 1 : a.name.localeCompare(b.name))
                                .reduce((acc, curr) => ({ ...acc, [curr.uuid]: { ...curr, items: [] } }), {});
                        sortedDashboards.forEach(r => foldersMap[r.groupUuid || "uncategorized"]?.items.push(r));

                        let result = Object.values(foldersMap);
                        if (getRole(user as User) === 'guest')
                                result = result.filter(f => f.items?.length);

                        return [favoritedFolder, ...result];
                },
                sortedModelsFolders: ({ modelsFolders, models, favoriteModels }): NavFolder[] => {
                        const sortedModels = models.sort((a, b) => a.name.localeCompare(b.name));
                        const favoriteUuids = favoriteModels.map(m => m.uuid);
                        const foldersMap: Record<string, NavFolder> = [asFolder('favorites', 'My favorites', 'yellow'), ...modelsFolders]
                                .sort((a, b) => a.name === 'uncategorized' ? 1 : a.name.localeCompare(b.name))
                                .reduce((acc, curr) => ({ ...acc, [curr.uuid]: { ...curr, items: [] } }), {});
                        sortedModels.forEach(r => {
                                if (favoriteUuids.includes(r.uuid)) {
                                        foldersMap["favorites"]?.items.push(r);
                                } else {
                                        foldersMap[r.groupUuid || "uncategorized"]?.items.push(r);
                                }
                        });

                        const { favorites, ...allFolders } = foldersMap;

                        const user = useAuthApi().user;
                        let filtered = Object.values(allFolders);
                        if (getRole(user as User) === 'guest')
                                filtered = filtered.filter(f => f.items?.length);

                        return [favorites, ...filtered].map(c =>
                        ({ ...c, items: c.items.sort((a, b) => a.name.localeCompare(b.name)) }
                        ));
                },
                sortedDatabasesFolders: ({ databasesFolders, databases }): NavFolder[] => {
                        const sortedDatabases = databases.sort((a, b) => a.name.localeCompare(b.name));
                        const foldersMap: Record<string, NavFolder> = databasesFolders
                                .sort((a, b) => a.name === 'uncategorized' ? 1 : a.name.localeCompare(b.name))
                                .reduce((acc, curr) => ({ ...acc, [curr.uuid]: { ...curr, items: [] } }), {});
                        sortedDatabases.forEach(r => foldersMap[r.groupUuid || "uncategorized"]?.items.push(r));

                        const user = useAuthApi().user;
                        let filtered = Object.values(foldersMap);
                        if (getRole(user as User) === 'guest')
                                filtered = filtered.filter(f => f.items?.length);

                        return [...filtered];
                },
                sortedDatasourcesFolders: ({ datasourcesFolders }): NavFolder[] => {
                        return [...datasourcesFolders].sort((a, b) => a.name.localeCompare(b.name))
                },
                findGroup: ({ dashboardsFolders, modelsFolders, databasesFolders, datasourcesFolders }) => (groupUuid: string) => {
                        return [...dashboardsFolders, ...modelsFolders, ...databasesFolders, ...datasourcesFolders].find(r => r.uuid == groupUuid) as NavFolder;
                },
                getMetricsAsFolders: () => {
                        // XXX TODO: Fetch metrics through navi nstead of metrics api
                        // filter here
                        const api = useMetricsApi();
                        const categoriesMap = {
                                'favorites': asFolder('favorites', 'My favorites', 'yellow'),
                                ...api.categories.reduce((acc, curr) => ({ ...acc, [curr.uuid]: asFolder(curr.uuid, curr.name, curr.color) }), {}),
                                'uncategorized': asFolder('uncategorized', 'Uncategorized')
                        };
                        const ratingColorsMap = {
                                "even": 'yellow',
                                "good": "green",
                                "bad": "red"
                        };
                        api.metrics.forEach(m => {
                                const item = {
                                        name: m.name,
                                        type: 'metric',
                                        uuid: m.code,
                                        ratingColor: ratingColorsMap[getRating(m.currentQuarterly, m.lowerIsBetter)]
                                };
                                if (m.favorited) categoriesMap['favorites']?.items.push(item);
                                if (m.categoryUuid) categoriesMap[m.categoryUuid as string]?.items.push(item);
                                else categoriesMap['uncategorized']?.items.push(item);
                        });

                        const user = useAuthApi().user;
                        let result = Object.values(categoriesMap);
                        if (getRole(user as User) === 'guest')
                                result = result.filter(f => f.items?.length || f.uuid === 'favorites');

                        return result
                                .sort((a, b) => {
                                        if (a.uuid === 'favorites' || b.uuid === 'favorites') return 1;
                                        return a.name.localeCompare(b.name);
                                })
                                .map(c =>
                                ({ ...c, items: c.items.sort((a, b) => a.name.localeCompare(b.name)) }
                                ));
                },
                getDatasourcesItems({ datasourcesFolders }) {
                        // XXX TODO: Fetch datasources through nav instead of ds api?
                        const dsApi = useDatasourcesApi();
                        const datasources = dsApi.datasources.filter(ds => ds.type != 'database')

                        function insertToSection(ds: Datasource, sectionId: string, result: Record<string, any>, label?: string, icon?: string) {
                                const item = { ...asLink(ds, "datasource", label), icon, depth: 1 }
                                const group = result[sectionId]
                                if (group === undefined) {
                                        const key = 'conn-' + ds.source.uuid
                                        result[key] = {
                                                label: ds.source.name,
                                                type: "folder",
                                                uuid: key,
                                                children: [item],
                                                folderIcon: "DatabaseIcon",
                                        }
                                        return
                                }
                                const index = group.children?.findIndex((c) => item.label.localeCompare(c.label) < 0) as number;
                                if (index === -1 || !group.children?.length) {
                                        group.children = [...(group.children || []), item]
                                        return
                                }
                                group.children.splice(index, 0, item)
                        }

                        const foldersRes: Record<string, SidebarMenuItem> = [
                                asFolder('favorites', 'My favorites', 'yellow'),
                                ...(datasourcesFolders || []),
                                asFolder('uncategorized', 'Uncategorized')
                        ]
                                .toSorted((a, b) => a.name.localeCompare(b.name))
                                .reduce((a, c) =>
                                        ({ ...a, [c.uuid]: { ...asFolder(c.uuid, c.name, c.color) } }),
                                        {} as Record<string, any>
                                )
                        const sourcesRes: Record<string, SidebarMenuItem> = {
                                uploads: {
                                        label: "Uploads",
                                        uuid: "uploads",
                                        type: "folder",
                                        folderIcon: "UploadIcon",
                                        children: [],
                                },
                                shared_tables: {
                                        label: "Shared Tables",
                                        uuid: "shared_tables",
                                        type: "folder",
                                        folderIcon: "TableIcon",
                                        children: []
                                },
                        }
                        for (let i = 0; i < datasources.length; i++) {
                                const ds = datasources[i];

                                let sectionId;
                                if (ds.type === "uploader") {
                                        sectionId = "uploads"
                                } else if (ds.type === "shared_table") {
                                        sectionId = "shared_tables"
                                } else {
                                        sectionId = "conn-" + ds.source.uuid
                                }

                                let dsGroupUuid = ds.groupUuid || 'uncategorized';
                                if (this.getIsFavorited(ds.uuid)) {
                                        dsGroupUuid = 'favorites'
                                }

                                insertToSection(
                                        ds,
                                        dsGroupUuid,
                                        foldersRes,
                                        ds.type === 'connection' ? `${ds.source.name}: ${ds.name}` : `${ds.name}`,
                                        sectionId === 'uploads' ?
                                                'UploadIcon'
                                                : sectionId === 'shared_tables' ? 'TableIcon' : 'DatabaseIcon'
                                )
                                insertToSection(ds, sectionId, sourcesRes)
                        }
                        if (!sourcesRes['shared_tables']?.children?.length)
                                delete sourcesRes['shared_tables']
                        const result: SidebarMenuItem[] = [
                                {
                                        label: "Datasources by source",
                                        uuid: "datasources-by-source",
                                        type: "folder",
                                        folderIcon: 'none',
                                        children: Object.values(sourcesRes),
                                        withChildCount: false,
                                        extraStyles: { marginTop: '1rem' },
                                        labelExtraStyles: { fontSize: '16px', height: '2rem' }
                                }
                        ]
                        const user = useAuthApi().user;
                        const role = getRole(user as User)
                        if (role === "guest") {
                                for (const id in result) {
                                        if (foldersRes[id] && !result[id]?.children?.length) {
                                                delete result[id]
                                        }
                                }
                        }
                        if (Object.keys(foldersRes)?.length) {
                                result.unshift({
                                        label: "Datasources by folder",
                                        uuid: "datasources-by-folder",
                                        type: "folder",
                                        folderIcon: 'none',
                                        children: Object.values(foldersRes),
                                        withChildCount: false,
                                        extraStyles: { marginTop: '1rem' },
                                        labelExtraStyles: { fontSize: '16px', height: '2rem' }
                                })
                        }

                        return result;
                }
        },
        actions: {
                async fetchNavSections() {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const sections = await navApi.fetchUserNavigationSections({ workspaceCode });
                        this.dashboardsFolders = [...sections.dashboardsGroups || [], {
                                uuid: "uncategorized",
                                name: "Uncategorized",
                                color: "gray",
                                workspaceUuid: useWorkspaceApi()._current?.uuid as string,
                                items: [],
                                type: "dashboard"
                        } as NavFolder];
                        this.dashboards = sections.dashboards;
                        this.favoriteDashboards = sections.favoriteDashboards;
                        this.models = sections.models;
                        this.modelsFolders = [...sections.modelsGroups || [], {
                                uuid: "uncategorized",
                                name: "Uncategorized",
                                color: "gray",
                                workspaceUuid: useWorkspaceApi()._current?.uuid as string,
                                items: [],
                                type: "model"
                        } as NavFolder];
                        this.favoriteModels = sections.favoriteModels;

                        this.databases = sections.databases;
                        this.databasesFolders = [...sections.databasesGroups || [], {
                                uuid: "uncategorized",
                                name: "Uncategorized",
                                color: "gray",
                                workspaceUuid: useWorkspaceApi()._current?.uuid as string,
                                items: [],
                                type: "database"
                        } as NavFolder];

                        this.datasourcesFolders = [...(sections.datasourcesGroups || []), {
                                uuid: "uncategorized",
                                name: "Uncategorized",
                                color: "gray",
                                workspaceUuid: useWorkspaceApi()._current?.uuid as string,
                                items: [],
                                type: "datasource"
                        } as NavFolder]

                        this.favoritesUuids = sections.favoritesUuid
                },
                async createFolder(name: string, type: GroupTypeEnum, color: Color) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const newFolder = await navApi.createGroup({
                                workspaceCode, createGroupParams: { name, type, color: color as string }
                        }
                        );
                        const updateKeyMap: Record<string, string> = {
                                "dahsboard": "dashbaordsFolders",
                                "model": "modelsFolders",
                                "datasource": "datasourcesFolders",
                                "database": "databasesFolders"
                        }
                        const updateKey = updateKeyMap[type]
                        if (updateKey) {
                                this[updateKey] = [...this[updateKey], newFolder]
                        }
                },
                async updateFolder(folderUuid: string, updates) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const currentFolder = this.findGroup(folderUuid);
                        await navApi.updateGroup({
                                groupUuid: folderUuid,
                                workspaceCode,
                                updateGroupParams: {
                                        updates
                                }
                        });
                        const updateKeyMap: Record<string, string> = {
                                "dashboard": "dashboardsFolders",
                                "model": "modelsFolders",
                                "datasource": "datasourcesFolders",
                                "database": "databasesFolders"
                        }
                        const updateKey = updateKeyMap[currentFolder.type]
                        if (updateKey) {
                                this[updateKey] = this[updateKey].map((f: any) => f.uuid === folderUuid ? { ...f, ...updates } : f);
                        }

                },
                async deleteFolder(folderUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const currentFolder = this.findGroup(folderUuid);
                        const dashboardsApi = useDashboardsApi();
                        const modelsApi = useModelsApi();
                        await navApi.deleteGroup({ workspaceCode, groupUuid: folderUuid });
                        if (currentFolder.type == 'dashboard') {
                                this.dashboards = this.dashboards.map(r => r.groupUuid === folderUuid ? { ...r, groupUuid: undefined } : r);
                                this.favoriteDashboards = this.favoriteDashboards.map(r => r.groupUuid === folderUuid ? { ...r, groupUuid: undefined } : r);
                                this.dashboardsFolders = this.dashboardsFolders.filter(f => f.uuid !== folderUuid);
                                dashboardsApi.dashboards = dashboardsApi.dashboards.map(r => r.groupUuid === folderUuid ? { ...r, groupUuid: undefined } : r);
                        } else if (currentFolder.type == "model") {
                                const modelsApi = useModelsApi();
                                this.modelsFolders = this.modelsFolders.filter(f => f.uuid !== folderUuid);
                                this.models = this.models.map(r => r.groupUuid === folderUuid ? { ...r, groupUuid: undefined } : r);
                                modelsApi.models = modelsApi.models.map(r => r.groupUuid === folderUuid ? { ...r, groupUuid: undefined } : r);
                                this.favoriteModels = this.favoriteModels.map(r => r.groupUuid === folderUuid ? { ...r, groupUuid: undefined } : r);
                        } else if (currentFolder.type === "datasource") {
                                const dsApi = useDatasourcesApi()
                                this.datasourcesFolders = this.datasourcesFolders.filter(f => f.uuid !== folderUuid)
                                dsApi.datasources = dsApi.datasources.map(ds => ds.groupUuid === folderUuid ? { ...ds, groupUuid: undefined } : ds)
                        } else if (currentFolder.type === "database") {
                                const dsApi = useDatabasesApi()
                                this.databasesFolders = this.databasesFolders.filter(f => f.uuid !== folderUuid)
                                dsApi.databases = dsApi.databases.map(ds => ds.groupUuid === folderUuid ? { ...ds, groupUuid: undefined } : ds)
                        }
                }
        }
});
export interface ExtendedDimension extends Dimension {
        selected: string,
        default: string,
}

export const useDimensionsApi = defineStore('api/dimensions', {
        state: () => ({
                wsDimensions: {} as Record<string, Dimension[]>,
                dimensionsValues: {} as Record<string, Record<string, string>[]>,
                mappings: [] as Mapping[],
                values: {} as Record<string, string[]>, // Dim code -> values[]
                metricDimensionValues: {} as Record<string, Record<string, string[]>>,
                dataSourceDimensionValues: {} as Record<string, Record<string, string[]>>
        }),
        getters: {
                dimensions: ({ wsDimensions }) => {
                        const current = useWorkspaceApi()._current as Workspace;
                        if (current)
                                return wsDimensions[current.uuid] || [];
                        return [];
                },
                getWorkspaceDimensions: ({ wsDimensions }) => (wsUuid: string) => {
                        return wsDimensions[wsUuid] || [];
                },
                getDimension() {
                        const dimensions: Dimension[] = this.dimensions;
                        return (uuid: string) => dimensions.find(d => d.uuid === uuid);
                },
                getDimensionByCode() {
                        const dimensions: Dimension[] = this.dimensions;
                        return (code: string) => dimensions.find(d => d.code === code);
                },
                getDimensionValues(): (val: string) => DimensionValue[] {
                        const dimensionsValues: Record<string, DimensionValue[]> = this.dimensionsValues;
                        return (uuid: string) => dimensionsValues[uuid] || [];
                },
                getDimensionDatasourcesWithMapping() {
                        const dimensions: Dimension[] = this.dimensions;
                        return (dimensionId: string) => {
                                const dsApi = useDatasourcesApi();
                                const dimension = dimensions.find(d => d.uuid === dimensionId);
                                return [...dsApi.datasources].filter(ds => ds.schema.some(col => col.which === dimension?.code));
                        };
                },
                dimensionsByUuid() {
                        const dimensions: Dimension[] = this.dimensions;
                        return dimensions.reduce((acc: Record<string, Dimension>, dim: Dimension) => {
                                acc[dim.uuid] = dim;
                                return acc;
                        }, {});
                },
                getMapping: ({mappings}) => (mappingUuid: string) => mappings.find(m => m.uuid === mappingUuid),
                getMappingByCode: ({mappings}) => (mappingCode: string) => mappings.find(m => m.code === mappingCode),
        },
        actions: {
                async fetchWorkspaceDimensions(force = false, wsUuid?: string) {
                        const wsApi = useWorkspaceApi();
                        const current = await wsApi.current() as Workspace; // will be defined, we have fetched it
                        if (this.wsDimensions[wsUuid || current.uuid]?.length && !force) return;
                        let workspaceCode = current.code;
                        if (wsUuid) {
                                const ws = wsApi.workspaces.find(w => w.uuid === wsUuid);
                                if (ws) workspaceCode = ws?.code;
                        }
                        this.wsDimensions[wsUuid || current.uuid] = (await dimensionsApi
                                .listWorkspaceDimensions({ workspaceCode }))
                                .sort((a, b) => a.name.localeCompare(b.name)
                                );
                },
                async fetchDimensionValues(dimUuid: string, force = false) {
                        if (!force && this.dimensionsValues[dimUuid]) return;
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const dimension: Dimension = this.getDimension(dimUuid);
                        const vals = await dimensionsApi.listDimensionValues({ workspaceCode, dimensionUuid: dimension.uuid });
                        this.dimensionsValues[dimUuid] = vals;
                },
                async createDimension(name: string, code?: string): Promise<Dimension | undefined> {
                        const currentWs = await useWorkspaceApi().current();
                        const workspaceCode = currentWs.code as string;
                        const newDimension = await dimensionsApi.createDimension({ workspaceCode, createDimensionParams: { name, code } });
                        const existing = this.dimensions.find(d => d.code == newDimension.code);
                        if (!existing) {
                                this.wsDimensions[currentWs.uuid] = [...this.wsDimensions[currentWs.uuid] || [], newDimension];
                        } else {
                                return existing;
                        }
                },
                async setDimensionDefaultValue(dimensionUuid: string, value: string) {
                        const currentWs = await useWorkspaceApi().current();
                        const workspaceCode = currentWs.code as string;
                        await dimensionsApi.setDimensionDefaultValue({ workspaceCode, dimensionUuid, setSelectedValueParams: { _default: value } });
                        this.wsDimensions[currentWs.uuid] = [...this.wsDimensions[currentWs.uuid] || []].map((d) => d.uuid === dimensionUuid ? { ...d, _default: value } : d);
                },
                async deleteDimension(dimensionUuid: string) {
                        const currentWs = await useWorkspaceApi().current();
                        const workspaceCode = currentWs.code as string;
                        await dimensionsApi.deleteDimension({ dimensionUuid, workspaceCode });
                        this.wsDimensions[currentWs.uuid] = this.wsDimensions[currentWs.uuid].filter(d => d.uuid !== dimensionUuid);
                },
                async fetchWorkspaceMappings(force = false) {
                        if (!force && this.mappings.length) return;
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        this.mappings = await dimensionsApi.listWorkspaceMappings({ workspaceCode });
                },
                async createMapping(mapping: Mapping) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const newMapping = await dimensionsApi.createMapping({
                                workspaceCode,
                                createMappingParams: {
                                        name: mapping.name,
                                        code: mapping.code,
                                        options: mapping.options,
                                        defaultValue: mapping.defaultValue,
                                        settings: _.isEmpty(mapping.settings) ? undefined : mapping.settings,
                                        uploadUuid: mapping.uploadUuid
                                }
                        });
                        this.mappings = [...this.mappings, newMapping];
                },
                async updateMapping(mapping: Mapping) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const updatedMapping = await dimensionsApi.updateMapping({
                                workspaceCode,
                                updateMappingParams: {
                                        mappingUuid: mapping.uuid,
                                        name: mapping.name,
                                        code: mapping.code,
                                        options: mapping.options,
                                        defaultValue: mapping.defaultValue,
                                        settings: _.isEmpty(mapping.settings) ? undefined : mapping.settings,
                                        uploadUuid: mapping.uploadUuid
                                }
                        });
                        this.mappings = this.mappings.map(m => m.uuid == mapping.uuid ? updatedMapping : m);
                },
                async deleteMapping(mappingUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dimensionsApi.deleteMapping({ workspaceCode, mappingUuid });
                        this.mappings = this.mappings.filter(m => m.uuid != mappingUuid);
                },
                async fuzzySearchDimValues(dimensionUuid: string, query?: string, datasourceCode?: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const data = await dimensionsApi.fuzzySearchDimValues({
                                workspaceCode,
                                dimensionUuid: dimensionUuid,
                                q: query,
                                entityUuid: datasourceCode
                        });
                        return data;
                },
                async addDimensionValue(dimensionUuid: string, attributes: Record<string, string>, entityUuid?: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dimensionsApi.addDimensionValue({ workspaceCode, dimensionUuid, addDimensionValuesParams: { attributes, entityUuid } });
                },
                async deleteDimensionValue(dimensionValueUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dimensionsApi.deleteDimensionValue({ workspaceCode, dimensionValueUuid });
                },
                async updateDimensionValue(dimensionValueUuid: string, attributes: Record<string, string>) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dimensionsApi.updateDimensionValue({ workspaceCode, dimensionValueUuid, updateDimensionValuesParams: { attributes } });
                },
        }
});


export const useDashboardsApi = defineStore('api/dashboards', {
        state: () => ({
                dashboards: [] as Dashboard[],
                tableValues: {} as Record<string, DashboardTableData | DashboardComparativeTableData | PiechartData>,
                tablesComputedAts: {} as Record<string, Date>,
                tableErrors: {} as Record<string, boolean>,
                dashboardSnapshots: {} as Record<string, string | null>,
                sharedTables: [] as SharedTable[],
                tablesSearchResult: [] as SimplifiedTable[],
                tabulationSelectedValues: [] as { dimUuid: string, values: string[] }[],
                tabulationAvailableValues: {} as Record<string, string[]>,
        }),
        getters: {
                getDashboard: ({ dashboards }) => (dashboardId: string) => dashboards.find(r => r.uuid === dashboardId),
                getIsFavorited: ({ dashboards }) => (dashboardId: string) => {
                        if (useNavApi().getIsFavorited(dashboardId)) return true;
                        const authApi = useAuthApi();
                        const dashboard = dashboards.find(r => r.uuid === dashboardId) as Dashboard;
                        return dashboard?.favoritedBy?.some(u => u.uuid === authApi.user?.uuid) as boolean;
                },
                getDashboardContributors: ({ dashboards }) => (dashboardId: string) => {
                        const dashboard = dashboards.find(r => r.uuid === dashboardId) as Dashboard;
                        return dashboard.contributors || [] as DashboardContributor[];
                },
                getTableDashboard: ({ dashboards }) => (tableId: string) => dashboards.find(r => r.tables.some(t => t.uuid === tableId)),
                getFiltersFromTabulationValues: ({tabulationSelectedValues}): Filter[] => {
                        return tabulationSelectedValues.reduce((a, {dimUuid, values}) => {
                                if (values?.length)
                                        a.push({
                                                field: dimUuid,
                                                operation: 'eq',
                                                values,
                                                _boolean: 'and',
                                                negated: false
                                        })
                                return a
                        }, [] as Filter[])
                }
        },
        actions: {
                async fetchAll(force = false) {
                        if (!force && this.dashboards?.length) return;
                        const wsApi = useWorkspaceApi();
                        const workspaceCode = (await wsApi.current()).code as string;
                        this.dashboards = await dashboardsApi.listWorkspaceDashboards({ workspaceCode });
                },
                async fetchDashboardDetails(dashboardUuid: string, workspaceUuid?: string) {
                        const wsApi = useWorkspaceApi();
                        const navApi = useNavApi();
                        let workspaceCode
                        if (workspaceUuid?.length) {
                                workspaceCode = wsApi.getWorkspacesMap[workspaceUuid]?.code
                        } else {
                                workspaceCode = (await wsApi.current()).code as string;
                        }
                        const {dashboard, tablesData} = await dashboardsApi.fetchDashboardDetails({
                                workspaceCode,
                                dashboardUuid,
                                includeTablesData: !this.tabulationSelectedValues.length,
                                // TODO: Add snapshot logic in snapshots PR
                        });

                        this.tableValues = {...this.tableValues, ...tablesData}

                        if (!workspaceUuid?.length) {
                                const isInState = this.dashboards.some(r => r.uuid === dashboardUuid);
                                this.dashboards = isInState ? this.dashboards.map((r: Dashboard) => r.uuid === dashboardUuid ? dashboard : r) : [...this.dashboards, dashboard];
                                const isFavorited = navApi.favoriteDashboards.some(r => r.uuid == dashboard.uuid);
                                const isInNav = navApi.dashboards.some(r => r.uuid == dashboard.uuid);
                                navApi.dashboards = !isFavorited && !isInNav ? [...navApi.dashboards, dashboard as NavSection] : [...navApi.dashboards];
                        }
                        return dashboard
                },
                async fetchByMetric(uuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        return await metricsApi.fetchDashboardsByMetric({
                                metricUuid: uuid,
                                workspaceCode
                        });
                },
                async createDashboard(name: string, groupUuid?: string) {
                        const navApi = useNavApi();
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const newDashboard = await dashboardsApi.createNewDashboard({ newDashboardParams: { name, groupUuid }, workspaceCode });
                        this.dashboards = [...this.dashboards, newDashboard];
                        navApi.dashboards = [...navApi.dashboards, { name: newDashboard.name, uuid: newDashboard.uuid, groupUuid }];
                        return newDashboard;
                },
                async changeDashboardFolder(dashboardUuid: string, newFolderUuid: string) {
                        const navApi = useNavApi();
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.updateDashboard({
                                dashboardUuid,
                                workspaceCode,
                                updateDashboardParams: {
                                        updates: {
                                                group_uuid: newFolderUuid === 'uncategorized' ? null : newFolderUuid
                                        }
                                }
                        });

                        this.dashboards = [...this.dashboards].map(r =>
                                r.uuid === dashboardUuid ? { ...r, groupUuid: newFolderUuid } : r
                        );
                        if (this.getIsFavorited(dashboardUuid)) {
                                navApi.favoriteDashboards = [...navApi.favoriteDashboards].map(r =>
                                        r.uuid === dashboardUuid ? { ...r, groupUuid: newFolderUuid } : r
                                );
                        } else {
                                navApi.dashboards = [...navApi.dashboards].map(r =>
                                        r.uuid === dashboardUuid ? { ...r, groupUuid: newFolderUuid } : r
                                );
                        }
                },
                async deleteDashboard(dashboardUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.deleteDashboard({ dashboardUuid, workspaceCode });
                        this.dashboards = this.dashboards.filter(r => r.uuid != dashboardUuid);
                },
                async updateDashboard(dashboardUuid: string, updates: UpdateDashboardParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.updateDashboard({ dashboardUuid, workspaceCode, updateDashboardParams: { updates } });
                        const camelizedUpdates = Object.entries(updates).reduce((acc, [key, val]) => ({ ...acc, [_.camelCase(key)]: val }), {});
                        this.dashboards = this.dashboards.map(r => r.uuid === dashboardUuid ? { ...r, ...camelizedUpdates } : r);
                        const navApi = useNavApi();
                        navApi.dashboards = navApi.dashboards.map(r => r.uuid === dashboardUuid ? { ...r, ...camelizedUpdates } : r);
                        navApi.favoriteDashboards = navApi.favoriteDashboards.map(r => r.uuid === dashboardUuid ? { ...r, ...camelizedUpdates } : r);
                },
                // Separate method to control this specifically
                async updateDashboardFilters(dashboardUuid: string, filters: Filter[]): Promise<void> {
                        await this.updateDashboard(dashboardUuid, { filters });
                },
                setDashboardSnapshot(dashboardUuid: string, snapshotUuid: string) {
                        // Used to trigger the action and reload the tables.
                        // TODO: Store this value somewhere (local storage?)
                        this.dashboardSnapshots[dashboardUuid] = snapshotUuid;
                },
                async updateDashboardContributors(dashboardUuid: string, newContributors: DashboardContributor[]) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;

                        await dashboardsApi.setDashboardContributors({
                                workspaceCode,
                                dashboardUuid,
                                setDashboardContributorsParams: {
                                        contributors: newContributors.reduce((a, c) => ({ ...a, [c.userUuid]: c.name }), {})
                                }
                        });

                        this.dashboards = this.dashboards.map(r => r.uuid === dashboardUuid ? { ...r, contributors: newContributors } : r);
                },
                async toggleFavorited(dashboardUuid: string, isFavorited: boolean) {
                        const navApi = useNavApi();
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;

                        await dashboardsApi.toggleFavorite({ workspaceCode, favoriteDashboardParams: { state: !isFavorited, dashboardUuid } });
                        const navDashboard = navApi.dashboards.find(r => r.uuid === dashboardUuid) || navApi.favoriteDashboards.find(r => r.uuid === dashboardUuid) as NavSection;
                        navApi.dashboards = isFavorited ? [...navApi.dashboards, { uuid: dashboardUuid, name: navDashboard?.name, emoji: navDashboard.emoji }] : navApi.dashboards.filter(r => r.uuid !== dashboardUuid);
                        navApi.favoriteDashboards = isFavorited ? navApi.favoriteDashboards.filter(r => r.uuid !== dashboardUuid) : [...navApi.favoriteDashboards, { uuid: dashboardUuid, name: navDashboard?.name, emoji: navDashboard.emoji }];
                        navApi.favoritesUuids = isFavorited ? navApi.favoritesUuids.filter(i => i !== dashboardUuid) : [...navApi.favoritesUuids, dashboardUuid];
                },
                async duplicateDashboard(dashboardUuid: string, name: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const response = await dashboardsApi.duplicateDashboard({ workspaceCode, dashboardUuid, duplicateDashboardParams: { name } });
                        return { newDashboardId: response.newDashboard, newReportMapping: response.mapping };
                },
                async addMetricToTable(tableUuid: string, dashboardUuid: string, metricUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const { data, computedAt, error } = await dashboardsApi.addMetricToTable({
                                workspaceCode,
                                tableUuid,
                                dashboardUuid,
                                addMetricToTableParams: { metricUuid }
                        });
                        this.tableValues[tableUuid] = data;
                        this.tablesComputedAts[tableUuid] = computedAt;
                        this.tableErrors[tableUuid] = error;
                },
                async removeMetricFromTable(tableUuid: string, dashboardUuid: string, metricUuid: string, refresh = false) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const { data, computedAt, error } = await dashboardsApi.removeMetricFromTable({
                                workspaceCode,
                                tableUuid,
                                dashboardUuid,
                                addMetricToTableParams: { metricUuid, refresh }
                        });
                        this.tableValues[tableUuid] = data;
                        this.tablesComputedAts[tableUuid] = computedAt;
                        this.tableErrors[tableUuid] = error;
                },
                async createTable(dashboardUuid: string, attributes?: TableAttributes): Promise<string> {
                        const workspaceCode = (await useWorkspaceApi().current())?.code as string;
                        const table = await dashboardsApi.createDashboardTable({
                                workspaceCode,
                                dashboardUuid,
                                createDashboardTableParams: {
                                        attributes
                                }
                        });
                        if (attributes?.type === "model") {
                                // TODO: Move from here to Models api, don't use default name
                                const navApi = useNavApi();
                                navApi.models = [...navApi.models, { name: "New model", uuid: table.uuid }];
                        }
                        this.dashboards = this.dashboards.map(r => r.uuid === dashboardUuid ? {
                                ...r,
                                tables: [...r.tables, table]
                        } : r);
                        return table.uuid;
                },
                async deleteTable(dashboardUuid: string, tableUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.deleteDashboardTable({ workspaceCode, dashboardUuid, tableUuid });
                },
                async updateTableAttributes(tableUuid: string, params: UpdateTableAttributesParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.updateTableAttributes({
                                workspaceCode, tableUuid, updateTableAttributesParams: params
                        });
                },
                async duplicateTable(
                        tableUuid: string,
                        originDashboardId: string,
                        targetDashboardUuid: string,
                        transformer: typeof TiptapTransformer,
                        attributesRemappings?: ImportTableRemapParams
                ) {
                        // XXX This is actually not good, the states between the backend table
                        // and the frontend might differ. We should use the backend table
                        // as the source of truth and construct the table from there.
                        // TODO: Move the styles to the backend table, as that is the only
                        // thing missing compared to the attrs, I think.
                        const provider = await useProvider(originDashboardId, new Doc(), 'dashboard', true, { dashboardUuid: originDashboardId });
                        const docJSON = transformer.fromYdoc(provider.doc);
                        const tableJSON = findTableJsonInDoc(docJSON['default'], tableUuid);
                        if (!tableJSON) return;

                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const newTable = await dashboardsApi.duplicateTable({
                                workspaceCode,
                                dashboardUuid: targetDashboardUuid,
                                tableUuid,
                                duplicateTableParams: {
                                        attributesRemappings
                                }
                        });
                        
                        // Replace UUIDs
                        const tiptapRemappingMap = {
                                ...(attributesRemappings?.metrics || {}),
                                ...(attributesRemappings?.dimensions || {}),
                                ...(attributesRemappings?.scenarios || {})
                        }
                        let replacedTableJson: string | Record<string, any> = JSON.stringify(tableJSON)
                        Object.entries(tiptapRemappingMap).forEach(([k, v]) =>
                                replacedTableJson = replacedTableJson.replaceAll(k, v)
                        )
                        replacedTableJson = JSON.parse(replacedTableJson) as Record<string, any>
                        
                        replacedTableJson['attrs']['tableId'] = newTable.uuid;
                        replacedTableJson['attrs']['tableUuid'] = newTable.uuid;

                        return replacedTableJson;
                },
                async fetchTableData(dashboardUuid: string, tableUuid: string, refresh: boolean, attributes: TableAttributes) {
                        if (this.tableValues[tableUuid] != undefined && !refresh) return;
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const snapshotUuid = this.dashboardSnapshots[dashboardUuid];
                        const tabulationFilters = this.getFiltersFromTabulationValues
                        const { data, computedAt, error } = await dashboardsApi.fetchTableData({
                                workspaceCode,
                                tableUuid,
                                tableDataParams: {
                                        refresh,
                                        attributes,
                                        snapshotUuid,
                                        filters: tabulationFilters || []
                                }
                        });
                        this.tableValues[tableUuid] = data;
                        this.tableErrors[tableUuid] = error;
                        this.tablesComputedAts[tableUuid] = computedAt;
                },
                async downloadTableCSV(tableUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const file = await dashboardsApi.downloadTableCSV({
                                workspaceCode,
                                tableUuid
                        });
                        const blob = new Blob([file]);
                        useDownload(blob, `table_${DateTime.now().toFormat("dd-MM-yyyy_HH:mm")}.csv`);
                },
                async downloadTableXls(
                        tableUuid: string,
                        period: [string, string],
                        title = "Untitled table",
                        extraStyles?: Record<any, any>,
                        metricsList?: string[]
                ) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        // XXX Need to do this fetch manually because I dont know how to tell openapi to use BlobApiResponse instead of TextResponse.
                        const fetchUrl = `${cfg.baseURL}/${workspaceCode}/dashboards/table/${tableUuid}/xls`;
                        await fetch(fetchUrl, {
                                method: "POST",
                                body: JSON.stringify({
                                        'row_styles': extraStyles || {},
                                        'metrics_list': metricsList || []
                                }),
                                headers: {
                                        'Content-Type': 'application/json',
                                        Cookie: document.cookie
                                },
                                credentials: "include"
                        }).then((response) => {
                                response.blob()
                                        .then((blob) =>
                                                useDownload(
                                                        blob,
                                                        `${title}_${period[0]}-${period[1]}.xlsx`
                                                )
                                        );
                        });
                },
                async fetchSharedTables(force: boolean = false) {
                        if (this.sharedTables.length && !force) return;
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const result = await dashboardsApi.listWorkspaceSharedTables({ workspaceCode });
                        this.sharedTables = result;
                },
                async fetchSharedTablesFromOrigin(tableUuid: string): Promise<SharedTable[]> {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        return await dashboardsApi.listSharedTablesFromOriginTable({ workspaceCode, tableUuid });
                },
                async shareTableWithWorkspaces(tableUuid: string, targetWorkspacesUuids: string[]) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.shareTable({ workspaceCode, tableUuid, shareTableParams: { targetWorkspacesUuids } });
                },
                async updateSharedTableSchema(tableUuid: string, schema: SchemaColumn[]) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.updateSharedTableSchema({ workspaceCode, tableUuid, updateSchemaParams: { schema } });
                        this.sharedTables = this.sharedTables.map((t: SharedTable) => {
                                if (t.uuid == tableUuid) t.schema = schema;
                                return t;
                        });
                },
                async updateSharedTableDetails(tableUuid: string, updates: Record<string, any>) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.updateSharedTableDetails({ workspaceCode, tableUuid, updateSharedTableDetailsParams: { updates } });
                        this.sharedTables = this.sharedTables.map((t: SharedTable) => {
                                if (t.uuid == tableUuid) {
                                        t = {
                                                ...t,
                                                ...updates,
                                                isPublic: updates.is_public !== undefined ? updates.is_public : t.isPublic,
                                                groupUuid: updates.group_uuid !== undefined ? updates.group_uuid : t.groupUuid
                                        };
                                }
                                return t;
                        });
                },
                async syncSharedTable(tableUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.syncSharedTable({ workspaceCode, tableUuid });
                },
                async favoriteSharedTable(tableUuid: string, status: boolean){
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.favoriteSharedTable({workspaceCode, tableUuid, favoriteSharedTableParams: {favorited: status}});
                },
                async setSharedTableOwners(tableUuid: string, owners: SharedTableOwner[]){
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.setSharedTableOwners({ workspaceCode, tableUuid, setSharedTableOwnersParams: { owners: owners.map(o => o.userUuid)} });
                        this.sharedTables = this.sharedTables.map((t: SharedTable) => {
                                if (t.uuid == tableUuid) t.owners = owners;
                                return t;
                        });
                },
                async deleteSharedTable(tableUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await dashboardsApi.deleteSharedTable({ workspaceCode, tableUuid });
                        this.sharedTables = this.sharedTables.filter((t: SharedTable) => t.uuid != tableUuid);
                },
                async fuzzySearchReportBlocks(dashboardUuid: string, query?: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const result = await searchApi.fuzzySearchBlocks({
                                workspaceCode,
                                q: query
                        });
                        this.tablesSearchResult  = result;
                },
                async setTabSelectedValues(dimUuid: string, values: string[] | null) {
                        this.tableValues = {}

                        if (values === null) {
                                this.tabulationSelectedValues =  this.tabulationSelectedValues.filter(d => d.dimUuid !== dimUuid)
                                return
                        }

                        const idx = this.tabulationSelectedValues.findIndex((d) => d?.dimUuid === dimUuid)
                        if (idx === -1) {
                                this.tabulationSelectedValues = [...this.tabulationSelectedValues, {dimUuid, values}] 
                                return
                        }
                        this.tabulationSelectedValues[idx] = {dimUuid, values}
                }
        }
});

interface SearchParams {
        term?: string;
        categories?: string[];
}

export interface UpdateConnectionDetailsParams {
        name?: string
        description?: string
        is_public?: boolean
        group_uuid?: string | null
}

export const useConnectorsApi = defineStore('api/connectors', {
        state: () => ({
                connectors: [] as BaseConnector[],
                connections: [] as ExtendedConnection[],
                statuses: {} as Record<string, string>,
                metrics: [] as ConnectorMetric[],
                selectedMetrics: [] as ConnectorMetric[]
        }),
        getters: {
                categories: ({ connectors }) => (
                        connectors.reduce(
                                (trail, { categories = [] }) => {
                                        const filtered = categories.filter(x => !trail.some(({ uuid }) => x === uuid));
                                        return [...trail, ...filtered.map(x => ({ uuid: x, name: x }))];
                                },
                                [] as { uuid: string; name: string }[]
                        )
                ),
                searchConnectors: ({ connectors }) => ({ term, categories = [] }: SearchParams) => {
                        // search by term
                        const termSearch = ({ name }: BaseConnector) => {
                                if (term) {
                                        return name.toLowerCase().includes(term);
                                }
                                return true;
                        };
                        const categorySearch = ({ categories: connCats }: BaseConnector) => {
                                if (categories.length) {
                                        return categories.some(cat => connCats.includes(cat));
                                }
                                return true;
                        };
                        return connectors.filter(c => c.type == BaseConnectorTypeEnum.Custom).filter(termSearch).filter(categorySearch);
                },
                searchMetrics: ({ metrics }) => (term: string) => metrics.filter(
                        ({ name }) => {
                                if (term) {
                                        return name.toLowerCase().includes(term);
                                }
                                return true;
                        }
                ),
                getConnectionStatus: ({ statuses }) => (uuid: string) => statuses[uuid] || "ok",
                getConnectionStreamStatus: ({ statuses }) => (uuid: string) => statuses[uuid] || "ok",
                getStream() {
                        return (connectionStreamUuid: string) => this.streams.find(s => s.uuid === connectionStreamUuid);
                },
                getStreamConnection: ({ connections }) => (connectionStreamUuid: string) => (
                        connections.find(c => c.streams?.some(s => s.uuid === connectionStreamUuid))
                ),
                streams: ({ connections }) => connections.reduce((all, curr) => [
                        ...all,
                        ...(curr.streams || [])
                ], [] as ConnectionStream[])
        },
        actions: {
                async createConnection(connectorUuid: string, params: CreateConnectionParams) {
                        const wsApi = useWorkspaceApi();
                        const workspaceCode = (await wsApi.current()).code as string;
                        await connApi.createConnection({
                                connectorUuid,
                                workspaceCode,
                                createConnectionParams: params
                        });
                },
                async fetchConnectors() {
                        const wsApi = useWorkspaceApi();
                        const workspaceCode = (await wsApi.current()).code as string;
                        const baseConnectors = await connApi.listConnectors({ workspaceCode });
                        this.connectors = baseConnectors;
                },
                async fetchConnections() {
                        const wsApi = useWorkspaceApi();
                        const workspaceCode = (await wsApi.current()).code as string;
                        this.connections = await connApi.listConnections({ workspaceCode });
                },
                async fetchConnectionStatus(connectionUuid: string): Promise<void> {
                        const wsApi = useWorkspaceApi();
                        const workspaceCode = (await wsApi.current()).code as string;
                        const status = await connApi.getConnectionStatus({ workspaceCode, connectionUuid });
                        this.statuses[connectionUuid] = status;
                        if (status == 'pending') {
                                setTimeout(() => this.fetchConnectionStatus(connectionUuid), 5000);
                        }
                },
                async runConnection(connectionUuid: string) {
                        this.statuses[connectionUuid] = 'pending';
                        const wsApi = useWorkspaceApi();
                        const workspaceCode = (await wsApi.current()).code as string;
                        const isRunning = await connApi.isConnectionRunning({ connectionUuid, workspaceCode }) as unknown as string;
                        if (isRunning) {
                                setTimeout(() => this.runConnection(connectionUuid), 1000);
                                return;
                        }
                        await connApi.runConnection({ connectionUuid, workspaceCode });
                        await this.fetchConnectionStatus(connectionUuid);
                },
                async resetConnection(connectionUuid: string) {
                        this.statuses[connectionUuid] = 'pending';
                        const wsApi = useWorkspaceApi();
                        const workspaceCode = (await wsApi.current()).code as string;
                        const isRunning = await connApi.isConnectionRunning({ connectionUuid, workspaceCode }) as unknown as string;
                        if (isRunning) {
                                return;
                        }
                        await connApi.resetConnection({ connectionUuid, workspaceCode });
                        await this.fetchConnectionStatus(connectionUuid);
                },
                async runStream(streamUuid: string) {
                        const conn = this.getStreamConnection(streamUuid);
                        if (!conn) return;
                        this.statuses[conn.uuid] = 'pending';
                        const wsApi = useWorkspaceApi();
                        const workspaceCode = (await wsApi.current()).code as string;
                        const isRunning = await connApi.isConnectionRunning({ connectionUuid: conn.uuid, workspaceCode }) as unknown as string;
                        if (isRunning) {
                                setTimeout(() => this.runStream(conn.uuid), 1000);
                                return;
                        }
                        await connApi.runStream({ streamUuid, workspaceCode });
                        await this.fetchStreamStatus(streamUuid);
                },
                async fetchStreamStatus(streamUuid: string) {
                        const wsApi = useWorkspaceApi();
                        const workspaceCode = (await wsApi.current()).code as string;
                        const status = await connApi.getStreamStatus({ workspaceCode, streamUuid });
                        this.statuses[streamUuid] = status;
                },
                async favoriteConnectionStream(streamUuid: string, favorited: boolean) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await connApi.favoriteConnectionStream({
                                connectionStreamUuid: streamUuid, workspaceCode, favoriteConnectionStreamParams: { favorited }
                        });
                },
                async removeConnection(connectionUuid: string) {
                        const wsApi = useWorkspaceApi();
                        const workspaceCode = (await wsApi.current()).code as string;
                        await connApi.removeConnection({ connectionUuid, workspaceCode });
                        this.connections = this.connections.filter(c => c.uuid !== connectionUuid);
                },
                async fetchMetrics(uuid: string) {
                        const wsApi = useWorkspaceApi();
                        const workspaceCode = (await wsApi.current()).code as string;
                        this.metrics = await connApi.fetchConnectorMetrics({ connectorUuid: uuid, workspaceCode });
                },
                async toggleMetricSelection(id: string) {
                        const metric = this.metrics.find(({ uuid }) => uuid === id) as ConnectorMetric;
                        this.selectedMetrics = this.selectedMetrics.some(({ uuid }) => uuid === metric.uuid)
                                ? this.selectedMetrics.filter(({ uuid }) => uuid !== metric.uuid)
                                : [...this.selectedMetrics, metric];
                },
                async clearSelection() {
                        this.selectedMetrics = [];
                },
                async updateConnectionStreamDetails(connectionStreamUuid: string, updates: UpdateConnectionDetailsParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await connApi.updateConnectionStreamDetails({ connectionStreamUuid, workspaceCode, updateConnectionStreamParams: { updates } });
                        this.connections = this.connections.map(c => ({
                                ...c, streams: c.streams?.map(s =>
                                        s.uuid === connectionStreamUuid ?
                                                {
                                                        ...s,
                                                        ...{
                                                                ...updates,
                                                                isPublic: updates.is_public !== undefined ? updates.is_public : s.isPublic,
                                                                groupUuid: updates.group_uuid !== undefined ? updates.group_uuid : s.groupUuid
                                                        }
                                                } : s
                                )
                        })
                        );
                },
                async updateConnectionStreamOwners(streamUuid: string, newOwners: ConnectionStreamOwner[]) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const stream = this.getStream(streamUuid) as ConnectionStream;
                        const oldOwners = stream.owners;

                        const toAdd = newOwners.reduce((acc, curr) => {
                                if (!oldOwners?.find(o => o.userUuid === curr.userUuid)) acc.push(curr.userUuid);
                                return acc;
                        }, [] as string[]);
                        const toRemove = oldOwners?.reduce((acc, curr) => {
                                if (!newOwners.find(o => o.userUuid === curr.userUuid)) acc.push(curr.userUuid);
                                return acc;
                        }, [] as string[]);

                        await connApi.addConnectionStreamOwners({ workspaceCode, streamUuid, addStreamOwnersParams: { userUuids: toAdd } });
                        await toRemove?.forEach(async userUuid =>
                                await connApi.removeConnectionStreamOwner({ workspaceCode, streamUuid, removeConnectionStreamOwnerParams: { userUuid } })
                        );

                        this.connections = this.connections.map(c =>
                                c.uuid === stream.connectionUuid ?
                                        { ...c, streams: c.streams?.map(s => s.uuid === streamUuid ? { ...s, owners: newOwners } : s) } : c
                        );
                },
                async updateConnectionStreamSchema(streamUuid: string, schema: SchemaColumn[]) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await connApi.updateConnectorSchema({ workspaceCode, streamId: streamUuid, updateSchemaParams: { schema } });
                        this.connections = [...this.connections.map(c => {
                                c.streams = c.streams?.map(s => {
                                        if (s.uuid == streamUuid) s.schema = schema;
                                        return s;
                                });
                                return c;
                        })];
                },
                async fetchMergeLink(type?: CreateConnectionLinkTypeEnum, connectionUuid?: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        return await connApi.createConnectionLink({ workspaceCode, type, connectionUuid });
                },
                async fetchRailzBusinessData() {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        return await connApi.fetchRailzBusinessData({ workspaceCode });
                },
                async connectionURL(connectionUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        return await connApi.connectionURL({ workspaceCode, connectionUuid });
                }
        }
});

function findNearestScenarioFormula(metric_uuid: string, scenarios: ScenarioTreeNode[], uuid: string): string {
        const metric = useMetricsApi().getMetric(metric_uuid);
        const scenario = findScenario(scenarios, uuid);
        // Return early if there is no scenario or metric has no formulas
        if (!scenario || !metric?.scenarioFormulas) return "";
        const formula = metric?.scenarioFormulas[scenario.uuid];
        // Return formula if formula is found
        if (formula) return formula;
        else if (scenario.sourceScenario) return findNearestScenarioFormula(metric_uuid, scenarios, scenario.sourceScenario);
        else return "";
}

function findScenario(scenarios: ScenarioTreeNode[], uuid: string): ScenarioTreeNode | undefined {
        return scenarios.reduce((found: ScenarioTreeNode | undefined, curr) => {
                if (found) {
                        return found;
                }
                if (curr.uuid === uuid) {
                        return curr;
                }
                return findScenario(curr.branchedScenarios || [], uuid);
        }, undefined);
}

function appendScenario(scenarios: ScenarioTreeNode[], scenario: ScenarioTreeNode, parent: string): ScenarioTreeNode[] {
        return scenarios.reduce((trail, curr) => {
                const { uuid, branchedScenarios: branched = [] } = curr;
                return [
                        ...trail,
                        {
                                ...curr,
                                branchedScenarios: uuid === parent ? [...branched, scenario] : appendScenario(branched, scenario, parent)
                        }
                ];
        }, [] as ScenarioTreeNode[]);
}

function removeScenario(scenarios: ScenarioTreeNode[], uuid: string): ScenarioTreeNode[] {
        return scenarios.reduce((trail, curr) => {
                if (curr.uuid === uuid) {
                        return trail;
                }
                return [...trail, { ...curr, branchedScenarios: removeScenario(curr.branchedScenarios || [], uuid) }];
        }, [] as ScenarioTreeNode[]);
}


const flattenScenarios = (scenarios: ScenarioTreeNode[]): ScenarioTreeNode[] => {
        return scenarios.reduce((acc, curr) => {
                return [...acc, ...flattenScenarios(curr.branchedScenarios || []), curr];
        }, [] as ScenarioTreeNode[]);
};


export interface ScenarioWithDate extends ScenarioTreeNode {
        closingDate?: Date
}

// HACK Necessary to format ISO string dates from backend. For some reason they are not Date
// objects already because of the openapi generator logic.
function formatClosingDate(closingDate: string | undefined): Date | undefined {
        if (!!closingDate && closingDate !== cfg.CLOSING_DATE_KEY) return new Date(closingDate as string);
        return closingDate as any;
}
export function formatScenariosClosingDate(scenarios: ScenarioTreeNode[]): ScenarioWithDate[] {
        return scenarios.map((curr) => {
                return {
                        ...curr,
                        closingDate: formatClosingDate(curr.closingDate as any),
                        branchedScenarios: formatScenariosClosingDate(curr.branchedScenarios || [])
                };
        });
}
// / HACK

export const useScenariosApi = defineStore("api/scenarios", {
        state: () => ({
                scenario: undefined as ScenarioWithDate | undefined,
                wsScenarios: {} as Record<string, ScenarioWithDate[]>
        }),
        getters: {
                scenarios: ({ wsScenarios }) => {
                        const current = useWorkspaceApi()._current as Workspace;
                        if (current)
                                return wsScenarios[current.uuid] || [];
                        return [];
                },
                getWorkspaceScenarios({ wsScenarios }) {
                        return function (wsUuid: string) {
                                return wsScenarios[wsUuid] || [];
                        };
                },
                getWorkspaceScenariosAsList({ wsScenarios }) {
                        return function (wsUuid: string) {
                                const scenarios = wsScenarios[wsUuid] || [];
                                return flattenScenarios(scenarios);
                        };
                },
                defaultScenario({ wsScenarios }) {
                        const currentWs = useWorkspaceApi()._current as Workspace;
                        return findScenario(this.scenarios, currentWs?.defaultScenarioUuid as string);
                },
                scenarioByUuid() {
                        return function (uuid: string) {
                                return findScenario(this.scenarios, uuid);
                        };
                },
                sortedScenarios() {
                        return this.scenarios.sort(
                                (s1: ScenarioWithDate, s2: ScenarioWithDate) => s1.name.localeCompare(s2.name)
                        );
                },
                findNearestFormula() {
                        return function (metric_uuid: string, uuid: string): string {
                                return findNearestScenarioFormula(metric_uuid, this.scenarios, uuid);
                        };
                },
                scenariosAsList() {
                        return flattenScenarios(this.scenarios);
                }
        },
        actions: {
                async fetchAll(force = false, wsUuid?: string) {
                        const wsApi = useWorkspaceApi();
                        const current = await wsApi.current() as Workspace; // will be defined, we have fetched it
                        if (this.wsScenarios[wsUuid || current.uuid]?.length && !force) return;
                        let workspaceCode = current.code;
                        if (wsUuid) {
                                const ws = wsApi.workspaces.find(w => w.uuid === wsUuid);
                                if (ws) workspaceCode = ws?.code;
                        }
                        const scenarios = (await scenariosApi.listWorkspaceScenarios({ workspaceCode }));
                        const formatted = formatScenariosClosingDate(scenarios);
                        this.wsScenarios[wsUuid || current.uuid] = formatted;
                },
                async createScenario(params: CreateScenario) {
                        const { sourceScenario } = params;
                        const current = await useWorkspaceApi().current();
                        const workspaceCode = current.code as string;

                        // XXX This offset is necessary to avoid day changes on the sent Date string,
                        // due to openapi generator using .toISO() to parse Dates to JSON
                        if (params.closingDate && params.closingDate != cfg.CLOSING_DATE_KEY) {
                                params.closingDate = jsDateToCorrectUtcDate(params.closingDate as Date);
                        }
                        // / XXX

                        const newScenario = await scenariosApi.createScenario({ workspaceCode, createScenario: params });
                        newScenario.closingDate = formatClosingDate(newScenario.closingDate as any);
                        this.wsScenarios[current.uuid] = sourceScenario ? appendScenario(this.scenarios, newScenario as ScenarioTreeNode, sourceScenario) : [...this.scenarios, newScenario as ScenarioTreeNode];
                },
                async updateScenario(uuid: string, updates: UpdateScenario) {
                        const current = await useWorkspaceApi().current();
                        const workspaceCode = current.code as string;

                        // XXX This offset is necessary to avoid day changes on the sent Date string,
                        // due to openapi generator using .toISO() to parse Dates to JSON
                        if (updates.closingDate && updates.closingDate != cfg.CLOSING_DATE_KEY) {
                                updates.closingDate = jsDateToCorrectUtcDate(updates.closingDate as Date);
                        }
                        // / XXX

                        await scenariosApi.updateScenario({ scenarioUuid: uuid, workspaceCode, updateScenario: updates });

                        // FIXME: really hard to reorder them on the store :(
                        const scenarios = (await scenariosApi.listWorkspaceScenarios({ workspaceCode }));
                        this.wsScenarios[current.uuid] = formatScenariosClosingDate(scenarios);
                },
                async deleteScenario(uuid: string) {
                        const wsApi = useWorkspaceApi();
                        const current = (await wsApi.current()) as Workspace; // will be defined, we have fetched it
                        const workspaceCode = current.code;
                        await scenariosApi.deleteScenario({ workspaceCode, scenarioUuid: uuid });
                        this.wsScenarios[current.uuid] = removeScenario(this.scenarios, uuid);
                }
        }
});

export interface UpdateUploaderDetailsParams {
        name?: string
        code?: string
        description?: string
        is_public?: boolean
        group_uuid?: string | null
}

export type UploadStatus = "pending" | "processed" | "failed";

export const useUploadersApi = defineStore('api/uploaders', {
        state: () => ({
                uploaders: [] as Uploader[],
                connections: [] as Connection[],
                tasks: [] as BatchProcessTask[]
        }),
        actions: {
                async fetchAll() {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current()).code as string;
                        this.uploaders = await uploadersApi.fetchDatasources({ workspaceCode });
                },
                async favoriteUploader(uuid: string, favorited: boolean) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current()).code as string;
                        await uploadersApi.favoriteDatasource({ workspaceCode, uploaderId: uuid, favoriteUploaderParams: { favorited } });
                },
                async createUploader(name: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const newUploader = await uploadersApi.createDatasource({ workspaceCode, createDatasourceParams: { name } });
                        this.uploaders = [...this.uploaders, newUploader];
                        const dsApi = useDatasourcesApi();
                        await dsApi.fetchUploaders();
                        return newUploader;
                },
                async deleteUploader(uploaderId: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await uploadersApi.deleteDatasource({ uploaderId, workspaceCode });
                        this.uploaders = this.uploaders.filter(u => u.uuid !== uploaderId);
                },
                async updateUploaderDetails(uploaderId: string, updates: UpdateUploaderDetailsParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await uploadersApi.updateDatasource({ workspaceCode, uploaderId, updateDataSourceParams: { updates } });
                        this.uploaders = this.uploaders.map(uploader => uploader.uuid === uploaderId ?
                                {
                                        ...uploader,
                                        ...{
                                                ...updates,
                                                isPublic: updates.is_public !== undefined ? updates.is_public : uploader.isPublic,
                                                groupUuid: updates.group_uuid !== undefined ? updates.group_uuid : uploader.groupUuid
                                        }
                                } : uploader
                        );
                },
                async updateUploaderOwners(uploaderUuid: string, newOwners: UploaderOwner[]) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const uploader = this.uploaders.find(u => u.uuid === uploaderUuid) as Uploader;
                        const oldOwners = uploader.owners;

                        const toAdd = newOwners.reduce((acc, curr) => {
                                if (!oldOwners?.find(o => o.userUuid === curr.userUuid)) acc.push(curr.userUuid);
                                return acc;
                        }, [] as string[]);
                        const toRemove = oldOwners?.reduce((acc, curr) => {
                                if (!newOwners.find(o => o.userUuid === curr.userUuid)) acc.push(curr.userUuid);
                                return acc;
                        }, [] as string[]);

                        await uploadersApi.addUploaderOwners({ workspaceCode, uploaderUuid, addUploaderOwnersParams: { userUuids: toAdd } });
                        await toRemove?.forEach(async userUuid =>
                                await uploadersApi.removeUploaderOwner({ workspaceCode, uploaderUuid, removeUploaderOwnersParams: { userUuid } })
                        );

                        this.uploaders = this.uploaders.map(u => u.uuid === uploaderUuid ? { ...u, owners: newOwners } : u);
                },
                async updateUploaderSchema(uploaderUuid: string, schema: SchemaColumn[]) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await uploadersApi.updateDatasourceSchema({ workspaceCode, uploaderId: uploaderUuid, updateSchemaParams: { schema } });
                        this.uploaders = this.uploaders.map(u => {
                                if (u.uuid == uploaderUuid)
                                        u.schema = schema;
                                return u;
                        });
                },
                async detectFileSchema(uploaderId: string, file: File) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const headers: string[] = await uploadersApi.detectUploaderFileSchema({ file, workspaceCode, uploaderId });
                        return headers;
                },
                async fetchTaskStatus(taskId: string): Promise<BatchProcessTask> {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const response = await uploadersApi.fetchTaskStatus({ workspaceCode, taskId });
                        return response;
                },
                async uploadFileToUploader(uploaderUuid: string, files: FileList): Promise<BatchProcessTask[]> {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const createdTasks = await uploadersApi.uploadDatasourceBatches({
                                workspaceCode,
                                uploaderUuid,
                                file: [...files]
                        });
                        const uploads = createdTasks.map(({ filename, uploadId, status }) => ({ filename, status, uuid: uploadId }) as UploadDetails);
                        this.tasks = [...this.tasks, ...createdTasks];
                        this.uploaders = this.uploaders.map(
                                (ds: Uploader) => ds.uuid === uploaderUuid ? {
                                        ...ds, uploads: [...(ds.uploads || []), ...uploads]
                                } : ds
                        );
                        return createdTasks;
                },
                async deleteUpload(uploaderId: string, batchId: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await uploadersApi.deleteBatch({ workspaceCode, batchId });
                        this.uploaders = this.uploaders.map(
                                (ds) => ds.uuid === uploaderId ? { ...ds, uploads: ds.uploads?.filter(({ uuid }) => uuid !== batchId) } : ds
                        );
                },
                async downloadUpload(batchId: string): Promise<Blob> {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const response = await uploadersApi.downloadBatch({ workspaceCode, batchId });
                        return new Blob([response], {
                                type: "text/csv;charset=utf-8;"
                        });
                }
        }
});


export type Datasource = {
        uuid: string;
        name: string;
        favorited: boolean;
        favoritedBy?: User[];
        favoritedByAdmins?: User[];
        uploads?: UploadDetails[];
        source?: Connection | Uploader | Database;
        stream?: ConnectionStream;
        type: 'uploader' | 'connection' | 'database' | 'shared_table';
        updatedAt: Date;
        schema: SchemaColumn[];
        code?: string;
        catalogCode?: string;
        connectorType?: BaseConnectorTypeEnum;
        groupUuid?: string
};

export type DatasourceValues = {
        table: string[][],
        columns: string[]
}
export const useDatasourcesApi = defineStore('api/datasources', {
        // FIXME: comodity to merge uploaders and connections, use the API for this instead
        state: () => ({
                datasources: [] as Datasource[],
                datasourceValues: {} as Record<string, DatasourceValues>,
                sourcesLoaded: false
        }),
        getters: {
                getDatasource: ({ datasources }) => (datasourceUuid: string) => datasources.find(d => d.uuid === datasourceUuid),
                sortedDatasources: ({ datasources }) => datasources.sort((a: Datasource, b: Datasource) => {
                        const sourceA = a.type == "connection" ? a.source.name : "CSV";
                        const sourceB = b.type == "connection" ? b.source.name : "CSV";
                        if (a.favorited === b.favorited) return sourceA.localeCompare(sourceB) != 0 ? sourceA.localeCompare(sourceB) : a.name.localeCompare(b.name);
                        return a.favorited ? -1 : 1;
                }),
                getDatasourceByCode: ({ datasources }) => (datasourceCode: string) => datasources.find(d => d.code == datasourceCode),
                getDatasourceBySource: ({ datasources }) => (uuid: string) => datasources.find(ds => ds.source?.uuid == uuid || ds.uuid === uuid),
                getDatasourceValues: ({ datasourceValues }) => (uuid: string): DatasourceValues => datasourceValues[uuid]
        },
        actions: {
                _combineDatasources() {
                        const conApi = useConnectorsApi();
                        const upApi = useUploadersApi();
                        const dbApi = useDatabasesApi();
                        const dashApi = useDashboardsApi();
                        const fields = ['name', 'code', 'favoritedBy', 'favoritedByAdmins', 'uploads', 'source', 'updatedAt', 'schema', 'groupUuid'];
                        const extract = (obj: Database | Uploader | ConnectionStream, fields: string[]): Partial<Datasource> => fields.reduce((trail, curr) => ({
                                ...trail,
                                [curr]: obj[curr]
                        }), {} as Partial<Datasource>);
                        const _formatDate = (ds: Datasource): string => {
                                if (ds.type === 'uploader') {
                                        const uploads = ds.uploads || [] as UploadDetails[];
                                        if (uploads.length) {
                                                const sorted = uploads.sort((a, b) => (
                                                        (a.processedAt as Date || new Date()).getMilliseconds() - (b.processedAt as Date || new Date()).getMilliseconds()
                                                ));
                                                const validDates = sorted.filter(u => u.processedAt).map(u => u.processedAt);
                                                const processedAt = validDates[validDates.length - 1];
                                                if (processedAt) return formatDate(processedAt as Date, "HH:mm:ss");
                                        }
                                        return "-";
                                }
                                return ds.updatedAt ? formatDate(ds.updatedAt as Date, "HH:mm:ss") : "Never";
                        };
                        const currUserId = useAuthApi().user?.uuid as string;
                        const isFavorited = (ds: Uploader | ConnectionStream) => (
                                !!ds.favoritedBy?.find(u => u.uuid === currUserId) || !!ds.favoritedByAdmins?.find(u => u.uuid === currUserId)
                        );
                        this.datasources = [
                                ...upApi.uploaders.map((uploader: Uploader) => ({
                                        uuid: uploader.uuid,
                                        ...extract(uploader, fields),
                                        favorited: isFavorited(uploader),
                                        type: 'uploader',
                                        connectorType: 'custom',
                                        source: uploader
                                })) as unknown as Datasource[],
                                ...conApi.connections.reduce((trail, curr) => {
                                        const streams = curr.streams || [];
                                        return [
                                                ...trail,
                                                ...streams.map((conn: ConnectionStream) => ({
                                                        ...extract(conn, fields),
                                                        uuid: conn.uuid,
                                                        source: curr,
                                                        favorited: isFavorited(conn),
                                                        stream: conn,
                                                        connectorType: curr.type,
                                                        type: "connection",
                                                        catalogCode: conn.catalogCode,
                                                        isMapping: false
                                                }) as Datasource
                                                )
                                        ];
                                }, [] as Datasource[]),
                                ...dbApi.databases.map((db: Database) => ({
                                        uuid: db.uuid,
                                        ...extract(db, fields),
                                        favorited: false,
                                        source: db,
                                        type: "database",
                                        createdAt: db.createdAt,
                                        groupUuid: db.groupUuid,
                                        isMapping: false
                                })),
                                ...dashApi.sharedTables.map((table: SharedTable) => ({
                                        uuid: table.uuid,
                                        name: table.name,
                                        favorited: false,
                                        type: "shared_table",
                                        schema: table.schema,
                                        groupUuid: table.groupUuid,
                                        source: table
                                }))
                        ].map(x => ({ ...x, updatedAt: _formatDate(x) }));
                },
                async fetchUploaders() {
                        await useUploadersApi().fetchAll();
                        this._combineDatasources();
                },
                async fetchAll(force = false) {
                        if (this.datasources.length && !force) return;
                        await useUploadersApi().fetchAll();
                        await useConnectorsApi().fetchConnections();
                        await useDatabasesApi().fetchDatabases(force);
                        await useDashboardsApi().fetchSharedTables(force);

                        this._combineDatasources();
                        this.sourcesLoaded = true;
                },
                async setFavorite(type: Datasource['type'], uuid: string, status: boolean) {
                        if (type === 'connection') {
                                const conApi = useConnectorsApi();
                                await conApi.favoriteConnectionStream(uuid, status);
                        } else if (type === 'uploader') {
                                const upApi = useUploadersApi();
                                await upApi.favoriteUploader(uuid, status);
                        } else if (type === "shared_table") {
                                const dbApi = useDashboardsApi();
                                await dbApi.favoriteSharedTable(uuid, status);
                        }
                        this.datasources = this.datasources.map(
                                (ds) => type === ds.type && uuid === ds.uuid ? { ...ds, favorited: status } : ds
                        );
                        const navApi = useNavApi();
                        navApi.favoritesUuids = status ? [...navApi.favoritesUuids, uuid] : navApi.favoritesUuids.filter(i => i !== uuid);
                },
                async updateDatasourceDetails(datasourceUuid: string, updates: UpdateConnectionDetailsParams | UpdateUploaderDetailsParams) {
                        const datasource = this.getDatasource(datasourceUuid) as Datasource;
                        if (datasource.type === "connection") {
                                await useConnectorsApi().updateConnectionStreamDetails(datasourceUuid, updates);
                        } else if (datasource.type === "uploader") {
                                await useUploadersApi().updateUploaderDetails(datasourceUuid, updates);
                        } else if (datasource.type === "shared_table") {
                                await useDashboardsApi().updateSharedTableDetails(datasourceUuid, updates);
                        }
                        this._combineDatasources();
                },
                async updateDatasourceOwners(datasourceUuid: string, owners: ConnectionStreamOwner[] | UploaderOwner[] | SharedTableOwner[]) {
                        const datasource = this.getDatasource(datasourceUuid) as Datasource;
                        if (datasource.type === "connection") {
                                await useConnectorsApi().updateConnectionStreamOwners(datasourceUuid, owners as ConnectionStreamOwner[]);
                        } else if (datasource.type === "uploader") {
                                await useUploadersApi().updateUploaderOwners(datasourceUuid, owners as UploaderOwner[]);
                        } else if (datasource.type === "shared_table") {
                                await useDashboardsApi().setSharedTableOwners(datasourceUuid, owners);
                        }
                        this._combineDatasources();
                },
                async deleteDatasource(datasourceUuid: string) {
                        const datasource = this.getDatasource(datasourceUuid) as Datasource;
                        if (datasource.type === "connection") {
                                console.warn("STREAM DELETION NOT IMPLEMENTED");
                        } else if (datasource.type === "uploader") {
                                await useUploadersApi().deleteUploader(datasourceUuid);
                        } else if (datasource.type === "shared_table") {
                                await useDashboardsApi().deleteSharedTable(datasourceUuid);
                        }
                        this._combineDatasources();
                },
                async updateDatasourceSchema(dsUuid: string, schema: SchemaColumn[]) {
                        const datasource = this.getDatasource(dsUuid) as Datasource;
                        if (datasource.type === "connection") {
                                await useConnectorsApi().updateConnectionStreamSchema(dsUuid, schema);
                        } else if (datasource.type === "uploader") {
                                await useUploadersApi().updateUploaderSchema(dsUuid, schema);
                        } else if (datasource.type === "shared_table") {
                                await useDashboardsApi().updateSharedTableSchema(dsUuid, schema);
                        }
                        this._combineDatasources();
                },
                _parseOverviewTable(datasource: Datasource | Database, data: Record<string, Record<number, unknown>>) {
                        const sourceByType = {
                                "connection": (datasource as ConnectionStream).stream as ConnectionStream,
                                "uploader": (datasource as Uploader).source as Uploader,
                                "shared_table": (datasource as SharedTable).source as SharedTable,
                                "database": datasource as Database
                        };
                        const source = sourceByType[datasource.type];
                        if (!source.schema) return { table: [], columns: [] };

                        // TODO: This should be done in backend. Why are we getting all the columns here?
                        const schemaCols = source.schema.map((col) => col.which || col.header);
                        const rowMask = data.headers.map((col: string) => schemaCols.findIndex((c: string) => c == col) > -1);
                        const maskedHeaders = data.headers.filter((_: string, i: number) => rowMask[i]);
                        // /TODO

                        const defaultDateCol = source.schema?.find((h) => h.type === "date" && h.isDefaultDate);

                        const maskedRows = data.rows.map((row: string[]) => row.filter((value: string, i) => rowMask[i]));

                        if (!defaultDateCol) {
                                return {
                                        columns: maskedHeaders,
                                        table: maskedRows
                                };
                        }

                        const defaultDateIdx = maskedHeaders.findIndex((h: string) => h === defaultDateCol.header);
                        const defaultDateFirst = (cols: string[]) => [cols[defaultDateIdx], ...cols.slice(0, defaultDateIdx), ...cols.slice(defaultDateIdx + 1)];
                        const columns = defaultDateFirst(maskedHeaders);
                        const rows = maskedRows.map((r: string[]) => defaultDateFirst(r));

                        const result = {
                                columns,
                                table: rows.sort(
                                        (a: string, b: string) =>
                                                DateTime.fromISO(a[0]).toMillis() -
                                                DateTime.fromISO(b[0]).toMillis()
                                )
                        };
                        return result;
                },
                _processDatabaseTable(table: DatasourceValues): DatasourceValues {
                        function buildName(source: Datasource) {
                                switch (source.type) {
                                        case "uploader": {
                                                return 'Upload: ' + source.name;
                                        }
                                        case "connection": {
                                                return `${source.source.name}: ${source.name}`;
                                        }
                                        default:
                                                return source.name;
                                }
                        }
                        const { table: rows, columns } = table;
                        const sourceDim = useDimensionsApi().getDimensionByCode("cofi_source")?.uuid;
                        const sourceTypeDim = useDimensionsApi().getDimensionByCode("cofi_source_type")?.uuid;
                        const sourcesMap: Record<string, Datasource> = this.datasources.reduce((acc: Record<string, Datasource>, ds: Datasource) => {
                                acc[ds.uuid] = ds;
                                return acc;
                        }, {});

                        const sourceTypeIndex = columns.findIndex(c => c === sourceTypeDim);

                        const result = {
                                columns: columns.filter(c => c != sourceTypeDim),
                                table: rows.map((row: string[]) => {
                                        const resultRow = row.map((value: string, i: number) => {
                                                const col = columns[i];
                                                if (col === sourceDim) {
                                                        const ds = sourcesMap[value];
                                                        return buildName(ds);
                                                }
                                                return value;
                                        }).filter((_r, i) => i != sourceTypeIndex);
                                        return resultRow;
                                })
                        };
                        return result;
                },
                async fetchDatasourceDataframe(datasourceUuid: string, page?: number, period: Period = storedPeriod(), filters?: Filter[]) {
                        const wsApi = useWorkspaceApi();
                        const workspace = await wsApi.current();
                        const workspaceCode = workspace.code as string;
                        const datasource = this.getDatasource(datasourceUuid) as Datasource;
                        if (datasource.type === "connection") {
                                const data = await connApi.fetchConnectionDf({
                                        workspaceCode,
                                        streamUuid: datasourceUuid,
                                        fetchConnectionDfParams: { page, filters, period }
                                });
                                this.datasourceValues[datasourceUuid] = this._parseOverviewTable(datasource, data as Record<string, Record<number, unknown>>);
                        } else if (["uploader", "shared_table"].includes(datasource.type)) {
                                const data = await uploadersApi.fetchUploaderDf({
                                        workspaceCode,
                                        uploaderId: datasourceUuid,
                                        fetchUploaderDfParams: { page, filters, period, sourceType: datasource.type }
                                });
                                this.datasourceValues[datasourceUuid] = this._parseOverviewTable(datasource, data as UploaderOverviewTableData);
                        } else if (datasource.type === "database") {
                                const dimensionsApi = useDimensionsApi();
                                const data = await databasesApi.databaseDataframe({
                                        workspaceCode,
                                        databaseUuid: datasourceUuid,
                                        fetchDatabaseParams: { page, filters, period }
                                });
                                if (!dimensionsApi.getDimensionByCode("cofi_source")) {
                                        await dimensionsApi.fetchWorkspaceDimensions(true);
                                }
                                const table = this._parseOverviewTable(datasource, data as OverviewTableData) as DatasourceValues;
                                this.datasourceValues[datasourceUuid] = this._processDatabaseTable(table);
                        }
                },
                async fetchTaskStatus(taskId: string): Promise<boolean> {
                        const upApi = useUploadersApi();
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const response = await upApi.fetchTaskStatus(taskId);
                        if (response.status !== 'pending' && response.uploadId) {
                                try {
                                        // remove from pile and refresh file
                                        const file = await uploadersApi.fetchBatchDetails({ workspaceCode, batchId: response.uploadId });
                                        upApi.tasks = upApi.tasks.filter(({ id }) => id !== taskId);
                                        upApi.uploaders = upApi.uploaders.map(
                                                (ds) => ds.uuid === file.uploaderUuid ? {
                                                        ...ds, uploads: ds.uploads?.map(
                                                                up => up.uuid === file.uuid ? file : up
                                                        )
                                                } : ds
                                        );
                                        this._combineDatasources();
                                        return false;
                                } catch (e) {
                                        if (e?.response?.status === 404) {
                                                // Upload was deleted while user saw it as pending, remove it from uploads
                                                const uploadId = upApi.tasks.find(({ id }) => id === taskId)?.uploadId;
                                                upApi.uploaders = upApi.uploaders.map((ds) =>
                                                        ds.uploads?.some(up => up.uuid === uploadId) ?
                                                                { ...ds, uploads: ds.uploads?.filter(u => u.uuid !== uploadId) } :
                                                                ds
                                                );
                                                upApi.tasks = upApi.tasks.filter(({ id }) => id !== taskId);
                                                this._combineDatasources();
                                                return false;
                                        }
                                }
                        }
                        return true;
                },
                async uploadFileToUploader(uploaderUuid: string, files: FileList) {
                        await useUploadersApi().uploadFileToUploader(uploaderUuid, files);
                        this._combineDatasources();
                },
                async removeUploaderUpload(uploaderId: string, uploadId: string) {
                        await useUploadersApi().deleteUpload(uploaderId, uploadId);
                        this._combineDatasources();
                },
                async downloadSourceCSV(sourceUuid: string, type: Datasource['type'], period, filters: Filter[] | null = null) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        let response;
                        if (type === 'connection') {
                                response = await connApi.downloadStreamAsCsv({ workspaceCode, streamUuid: sourceUuid, downloadCSVParams: { period, filters } });
                        } else if (type === 'uploader') {
                                response = await uploadersApi.downloadUploaderAsCsv({ workspaceCode, uploaderUuid: sourceUuid, downloadCSVParams: { period, filters } });
                        } else if (type === 'shared_table') {
                                response = await dashboardsApi.downloadSharedTableAsCsv({ workspaceCode, tableUuid: sourceUuid, downloadCSVParams: { period, filters } });
                        } else if (type === 'database') {
                                response = await databasesApi.downloadDatabaseAsCsv({ workspaceCode, databaseUuid: sourceUuid, downloadCSVParams: { period, filters } });
                        }
                        return new Blob([response], {
                                type: "text/csv;charset=utf-8;"
                        });
                }
        }
});

export type EnrichedMetric = Metric & {
        favorited?: boolean;
        category: string;
        currentQuarterly: number;
        trendQuarterly: number;
        currentYTD: number;
        trendYTD: number;
        values: Record<string, number>;
}

interface FetchBridgeDataParams {
        tableUuid: string;
        metricUuid: string;
        filters: Filter[];
        scenarios?: string[];
        dimensions?: string[];
        period: Period;
        refresh?: boolean;
}

export const naCategory = { name: "Uncategorized", uuid: undefined, color: "gray" };

interface FetchPiechartDataParams {
        tableUuid: string;
        metrics?: string[];
        dimensions?: string[];
        filters?: Filter[];
        scenarioUuid: string;
        period: Period;
        refresh?: boolean;
}

export interface UpdateMetricParams {
        name?: string
        category_uuid?: string
        description?: string
        unit?: string
        is_public?: boolean
        lower_is_better?: boolean
        granularity?: MetricGranularityEnum
        fill_value?: null | number
        time_aggregate_function?: string
        dim_aggregate_function?: string
}

interface FetchHistogramDataParams {
        tableUuid: string;
        scenarios?: string[];
        metrics?: string[]
        dimensions?: string[];
        filters?: Filter[];
        period?: Period;
        refresh?: boolean;
}

export const defaultFilter = (option: Dimension): Filter => ({
        operation: option.format ? FilterOperationEnum.Before : FilterOperationEnum.Eq,
        _boolean: FilterBooleanEnum.And,
        field: option.uuid,
        values: []
});
export const useMetricsApi = defineStore('api/metrics', {
        state: () => ({
                recomputing: false,
                units: [] as MetricUnit[],
                metrics: [] as EnrichedMetric[],
                favorited: [] as string[],
                categories: [] as MetricCategory[],
                dimensions: [] as Dimension[],
                filters: [] as Filter[],
                sources: {} as Record<string, SourcesData>,
                targets: {} as Record<string, SourcesChartNode>,
                valuesFetched: false,
        }),
        getters: {
                getMetric: ({ metrics }) => (metricUuid: string) => metrics.find(m => m.uuid === metricUuid),
                getFavorited: ({ metrics, favorited }) => () => metrics.filter(m => favorited.includes(m.uuid)),
                getMetricByCode: ({ metrics }) => (metricCode: string) => metrics.find(m => m.code === metricCode),
                searchCategories: ({ categories }) => (term: string) => [
                        { uuid: undefined, name: 'Uncategorized' },
                        ...categories
                ]
                        .filter(({ name }) => name.toLowerCase().includes(term.toLowerCase()))
                        .map(({ uuid, name }) => ({ uuid, label: name })),
                metricsByCategory: ({ metrics }) => (categories: Item[]) => metrics
                        .filter(({ categoryUuid }) => {
                                return categories.length === 0 || categories.some(c => c.uuid === categoryUuid);
                        })
                        .sort((a, b) => {
                                if (a.favorited == b.favorited) {
                                        return a.name.localeCompare(b.name);
                                }
                                return a.favorited ? -1 : 1;
                        }),
                metricById: ({ metrics }) => (id: string) => metrics.find(m => m.uuid === id) as Metric,
                metricByCode: ({ metrics }) => (code: string) => metrics.find(m => m.code === code) as Metric,
                metricSources: ({ sources }) => (code: string) => sources[code],
                metricTargets: ({ targets }) => (code: string) => targets[code],
                categoryByUuid: ({ categories }) => (uuid: string | undefined) => uuid ? categories.find(c => c.uuid === uuid) : naCategory,
                getMetricsUnitsMap: ({ metrics }) => metrics.reduce(
                        (acc, curr) => ({ ...acc, [curr.uuid]: curr.unit as string }), {} as Record<string, string>
                )
        },
        actions: {
                _enrichMetric(m: Metric, vals: Record<string, Record<string, number>>): EnrichedMetric {
                        const values = vals[m.uuid] || {};
                        const category = this.categories.find(({ uuid }) => uuid === m.categoryUuid) || naCategory;
                        return {
                                ...m,
                                favorited: this.favorited.includes(m.uuid),
                                category: category.name,
                                currentQuarterly: values.current_quarterly || 0,
                                trendQuarterly: values.trend_quarterly || 0,
                                currentYTD: values.current_ytd || 0,
                                trendYTD: values.trend_ytd || 0,
                                values: (values.values || {}) as Record<string, number>
                        };
                },
                async checkWorkspaceIsRecomputing(): Promise<boolean> {
                        const wsApi = useWorkspaceApi();
                        const current = await wsApi.current() as Workspace; // will be defined, we have fetched it
                        const workspaceCode = current.code;
                        try {
                                const response = await metricsApi.checkWorkspaceIsRecomputing({ workspaceCode });
                                this.recomputing = response.isRecomputing;
                        } catch (e: any) {
                                if (e?.response?.status === 401)
                                        return true;
                        }
                        return false;
                },
                async fetchMetrics(force = false) {
                        if (this.metrics.length && !force) return;
                        const wsApi = useWorkspaceApi();
                        const current = await wsApi.current() as Workspace; // will be defined, we have fetched it
                        const workspaceCode = current.code;
                        this.units = await metricsApi.fetchMetricUnits({ workspaceCode });
                        const { metrics, categories, favorited } = await metricsApi.fetchMetricsSummary({ workspaceCode });
                        this.categories = categories;
                        this.favorited = favorited.map(m => m.uuid);
                        this.metrics = metrics.map(m => this._enrichMetric(m, {}));
                },
                async fetchMetricUnits(force = false) {
                        if (this.units.length && !force) return;
                        const wsApi = useWorkspaceApi();
                        const current = await wsApi.current() as Workspace; // will be defined, we have fetched it
                        const workspaceCode = current.code;
                        this.units = await metricsApi.fetchMetricUnits({ workspaceCode });
                },
                async fetchMetricsValues() {
                        const wsApi = useWorkspaceApi();
                        await wsApi.fetchCurrent();
                        const current = await wsApi.current() as Workspace; // will be defined, we have fetched it
                        const workspaceCode = current.code;
                        const values = await metricsApi.fetchMetricsValues({ workspaceCode });
                        this.metrics = this.metrics.map(m => this._enrichMetric(m, values));
                        this.valuesFetched = true;
                },
                async forceMetricsRecompute() {
                        this.recomputing = true;
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await metricsApi.forceMetricsRecompute({ workspaceCode });
                },
                async updateMetric(metricUuid: string, updates: UpdateMetricParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const recompute = await metricsApi.updateMetric({ metricUuid, workspaceCode, updateMetric: { updates } })
                        if (recompute) this.recomputing = true;
                        const camelizedUpdates = Object.entries(updates).reduce((acc, [key, val]) => ({ ...acc, [_.camelCase(key)]: val }), {});
                        this.metrics = this.metrics.map(m => m.uuid === metricUuid ? { ...m, ...camelizedUpdates } : m);
                },
                async fetchMetric(uuid: string) {
                        // FIXME: missing api call for a single metric
                        await this.fetchMetrics();
                        return this.metrics.find(m => m.uuid === uuid);
                },
                async downloadMetricData(code: string) {
                        const prefs = usePreferences();
                        const period = prefs.period as Period;
                        const metric = this.metricByCode(code);
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const response = await metricsApi.exportMetricDataCSV({
                                metricUuid: metric.uuid,
                                workspaceCode,
                                pivotTableCsvParams: {
                                        dimensions: this.dimensions.map(x => x.uuid),
                                        period: prefs.period,
                                        filters: this.filters,
                                        periodSize: period.grain as string
                                }
                        });
                        const blob = new Blob([response], {
                                type: "text/csv;charset=utf-8;"
                        });
                        useDownload(blob, `metric_${code}_table_${DateTime.now().toFormat(
                                "dd-MM-yyyy_HH:mm"
                        )}.csv`);
                },
                async fetchComputedMetricValues(
                        uuid: string,
                        refresh: boolean,
                        period: Period,
                        backend?: string,
                        useFiscalYear = true,
                        base = true,
                        derived = false,
                        ignorePreaggregates?: boolean,
                        snapshotUuid?: string
                ) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        return await metricsApi.computeMetricValues({
                                metricUuid: uuid,
                                workspaceCode,
                                pivotTableParams: {
                                        period,
                                        dimensions: this.dimensions.map(({ uuid }) => uuid),
                                        filters: this.filters,
                                        refresh,
                                        backend,
                                        yearTotals: useFiscalYear ? "fiscal" : "natural",
                                        onlyBase: base,
                                        onlyDerived: derived,
                                        ignorePreaggregates,
                                        snapshotUuid
                                }
                        });
                },
                async selectDimension(uuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const dimensions = await dimensionsApi.listWorkspaceDimensions({
                                workspaceCode
                        });
                        this.dimensions = [
                                ...this.dimensions,
                                dimensions.find(d => d.uuid === uuid) as Dimension
                        ];
                },
                resetDimensions() {
                        this.dimensions = [];
                },
                addFilter(filter: Filter) {
                        this.filters = [filter, ...this.filters];
                },
                removeFilter(pos: number) {
                        this.filters = this.filters.filter((_, x) => x !== pos);
                },
                async favoriteMetric(uuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await metricsApi.setMetricFavoriteStatus({ workspaceCode, cofiApiRoutersMetricsFavoriteParams: { metricUuid: uuid, state: true } });
                        this.metrics = this.metrics.map(m => m.uuid === uuid ? { ...m, favorited: true } : m);
                        const metric = this.metrics.find(m => m.uuid === uuid) as EnrichedMetric;
                        this.favorited = [...this.favorited, metric.uuid];
                },
                async unfavoriteMetric(uuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await metricsApi.setMetricFavoriteStatus({ workspaceCode, cofiApiRoutersMetricsFavoriteParams: { metricUuid: uuid, state: false } });
                        this.metrics = this.metrics.map(m => m.uuid === uuid ? { ...m, favorited: false } : m);
                        this.favorited = this.favorited.filter(f => f !== uuid);
                },
                async createCategory(name: string, color: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        this.categories = [
                                ...this.categories,
                                await metricsApi.createCategory({ workspaceCode, createCategory: { name, color } })
                        ];
                },
                async updateCategory(categoryUuid: string, updates: UpdateCategoryParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await metricsApi.updateCategory({ workspaceCode, categoryUuid, updateCategoryParams: updates });
                        this.categories = this.categories.map((c: MetricCategory) => c.uuid === categoryUuid ? { ...c, ...updates } : c);
                },
                async deleteCategory(categoryUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        metricsApi.deleteCategory({ workspaceCode, categoryUuid });

                        this.categories = this.categories.filter(c => c.uuid !== categoryUuid);
                        this.metrics = this.metrics.map(m => this._enrichMetric(m, {
                                [m.uuid]: {
                                        current_quarterly: m.currentQuarterly || 0,
                                        trend_quarterly: m.trendQuarterly || 0,
                                        current_ytd: m.currentYTD || 0,
                                        trend_ytd: m.trendYTD || 0,
                                        values: (m.values || {}) as Record<string, number>
                                }
                        } as any));
                },
                async createMetric(name: string, connectionUuid?: string, catalogMetricCode?: string, categoryId?: string): Promise<string> {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const metric = await metricsApi.createMetric({ workspaceCode, createMetric: { name, connectionUuid, catalogMetricCode, categoryId } });
                        this.metrics = [
                                ...this.metrics,
                                this._enrichMetric(metric, {})
                        ];
                        return metric.uuid;
                },
                async deleteMetric(metricUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await metricsApi.deleteMetric({ metricUuid, workspaceCode });
                        this.metrics = this.metrics.filter(m => m.uuid !== metricUuid);
                },
                async updateMetricOwners(metricUuid: string, updatedOwners: MetricOwner[]) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const oldOwnersMap = this.getMetric(metricUuid)?.owners?.reduce((acc, curr) =>
                                ({ ...acc, [curr.userUuid]: curr }), {} as Record<string, MetricOwner>
                        ) as Record<string, MetricOwner>;

                        const toAdd = [] as string[];
                        const toRemove = [] as string[];
                        updatedOwners.forEach(owner => oldOwnersMap[owner.userUuid] ? null : toAdd.push(owner.userUuid));
                        Object.keys(oldOwnersMap).forEach(userId => updatedOwners.find(o => o.userUuid === userId) ? null : toRemove.push(userId));

                        await metricsApi.addMetricOwners({ metricUuid, workspaceCode, addMetricOwnersParams: { userUuids: toAdd } });
                        await toRemove.forEach(async (userUuid) => {
                                await metricsApi.removeMetricOwner({ metricUuid, workspaceCode, removeMetricOwnersParams: { userUuid } });
                        });
                        this.metrics = this.metrics.map(m => m.uuid === metricUuid ? { ...m, owners: updatedOwners } : m);
                },
                async refreshValues() {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const values = await metricsApi.forceMetricsRecompute({ workspaceCode });
                        this.metrics = this.metrics.map(m => this._enrichMetric(m, values));
                },
                async uploadMetricsFile(file: File) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await metricsApi.batchCreateMetrics({
                                workspaceCode,
                                file,
                                overwrite: true
                        });
                },
                async fetchMetricSources(code: string, scenario: string, period: Period, useFiscalYear: boolean = true, targets = false) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await this.fetchMetrics();
                        const metric = this.metrics.find(m => m.code === code) as Metric;
                        const data = await metricsApi.fetchMetricSources({
                                workspaceCode,
                                metricUuid: metric.uuid,
                                scenarioUuid: scenario,
                                metricSourcesParams: {
                                        period,
                                        yearTotals: useFiscalYear ? "fiscal" : "natural",
                                        reverse: targets
                                }
                        }) as SourcesData;
                        if (targets) this.targets[code] = data
                        else this.sources[code] = data;
                },
                async fetchMetricTargets(code: string, scenario: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await this.fetchMetrics();
                        const metric = this.metrics.find(m => m.code === code) as Metric;
                        const data = await metricsApi.fetchMetricTargets({
                                workspaceCode,
                                metricUuid: metric.uuid,
                                scenarioUuid: scenario,
                        }) as SourcesChartNode;
                        this.targets[code] = data
                },
                async updateMetricCode(uuid: string, code: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await metricsApi.updateMetricCode({
                                metricUuid: uuid,
                                workspaceCode,
                                updateProperty: {
                                        value: code
                                }
                        });
                        this.metrics = this.metrics.map(m => m.uuid === uuid ? { ...m, code } : m);
                },
                async updateMetricCategory(uuid: string, category: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await metricsApi.updateMetric({
                                workspaceCode,
                                metricUuid: uuid,
                                updateMetric: {
                                        updates: {
                                                category_uuid: category
                                        }
                                }
                        });
                        this.metrics = this.metrics.map(m => m.uuid === uuid ? { ...m, categoryUuid: category } : m);
                },
                async updateMetricUnit(uuid: string, unit: MetricUnitEnum) {
                        const currWs = await useWorkspaceApi().current();
                        await metricsApi.updateMetric({
                                workspaceCode: currWs?.code as string,
                                metricUuid: uuid,
                                updateMetric: {
                                        updates: { unit }
                                }
                        });
                        this.metrics = this.metrics.map(
                                (m: EnrichedMetric) => m.uuid === uuid ? { ...m, unit: unit || currWs?.defaultMetricUnit, usesDefaultUnit: !unit } : m
                        );
                },
                async duplicateMetric(uuid: string, name: string): Promise<EnrichedMetric> {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const newMetric = await metricsApi.duplicateMetric({
                                metricUuid: uuid, workspaceCode, duplicateMetricParams: { name }
                        });
                        const enrichedMetric = this._enrichMetric(newMetric, {});
                        this.metrics = [
                                ...this.metrics,
                                enrichedMetric
                        ];
                        await this.fetchMetricsValues();
                        return enrichedMetric;
                },
                async validateFormula(metricUuid: string, scenarioUuid: string, formula: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await metricsApi.validateMetricFormula({
                                workspaceCode,
                                metricUuid,
                                scenarioUuid,
                                validateFormulaParams: { formula }
                        });
                },
                async updateMetricFormula(metricUuid: string, scenarioUuid: string, formula: string, recomputeWarning = true) {
                        if (recomputeWarning) this.recomputing = true;
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const serialized = await metricsApi.updateMetricScenarioFormula({ workspaceCode, scenarioUuid, metricUuid, updateProperty: { value: formula } });
                        this.metrics = this.metrics.map((m: Metric): Metric => {
                                if (m.uuid == metricUuid) {
                                        return {
                                                ...m,
                                                scenarioFormulas: {
                                                        ...(m.scenarioFormulas || {}),
                                                        [scenarioUuid]: serialized
                                                }
                                        };
                                }
                                return m;
                        });
                },
                async fetchMetricDrillDown(params: ExtendedDrillDownParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        const results = await metricsApi.computeMetricDrilldown({ metricUuid: params.metricId, workspaceCode, drillDownParams: params });
                        return results;
                }
        }
});

export interface ExtendedDrillDownParams extends DrillDownParams {
        metricId: string;
}


export const useSupportApi = defineStore('api/support', {
        actions: {
                async reportBug(title: string, description: string, commit: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await supportApi.reportBugWorkspaceCodeBugsPost({ workspaceCode, reportBug: { title, description, commit } });
                }
        }
});

function fixZone(date: DateTime): DateTime {
        return date.setZone('UTC', { keepLocalTime: true }).setZone(Settings.defaultZone);
}

const _parseDiscussions = (discussions: Discussion[]): UIDiscussion[] => {
        const isRecentEnough = (_prevCommentDate: DateTime, _currentCommentDate: DateTime) => {
                return true; // XXX: Decide what we should consider recent enough
        };
        const wsApi = useWorkspaceApi();
        return discussions.map((discussion) => {
                const blocks = discussion.comments.reduce((acc: UICommentsBlock[], comment: Comment) => {
                        const last = acc.slice(-1)[0];
                        const createdAt = fixZone(DateTime.fromJSDate(comment.createdAt));
                        const author = comment.author;
                        if (last && last.author?.uuid == comment.author.uuid && isRecentEnough(last.createdAt, createdAt)) {
                                const trailing = [
                                        ...acc.slice(0, -1),
                                        { ...last, comments: [...last.comments, comment] }
                                ];

                                return trailing;
                        }
                        const newBlock: UICommentsBlock = {
                                createdAt,
                                author,
                                comments: [comment]
                        };
                        return [...acc, newBlock];
                }, [] as UICommentsBlock[]);

                return {
                        uuid: discussion.uuid,
                        targetId: discussion.targetUuid,
                        resolved: !!discussion.resolvedAt,
                        blocks
                };
        });
};

export const useDiscussionsApi = defineStore("api/discussions", {
        state: () => ({
                targetId: "",
                discussions: [] as UIDiscussion[]
        }),
        getters: {
                getDiscussions: ({ discussions }): UIDiscussion[] => discussions,
                getActiveDiscussions: ({ discussions }): UIDiscussion[] => discussions.filter(d => !d.resolved),
                getResolvedDiscussions: ({ discussions }): UIDiscussion[] => discussions.filter(d => !!d.resolved)
        },
        actions: {
                async fetchTargetDiscussions(targetId: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current()).code as string;
                        this.targetId = targetId;
                        this.discussions = _parseDiscussions(await discussionsApi.fetchTargetDiscussions({ targetId, workspaceCode }));
                },
                async fetchCommunityTargetDiscussions(targetId: string) {
                        this.targetId = targetId;
                        this.discussions = _parseDiscussions(await communityApi.fetchCommunityTargetDiscussions({ assetUuid: targetId }));
                },
                async createDiscussion(targetId: string, comment: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current()).code as string;
                        const updated = await discussionsApi.createDiscussion({ targetId, workspaceCode, createDiscussion: { comment } });
                        this.discussions = _parseDiscussions(updated);
                },
                async addComment(discussionId: string, targetId: string, comment: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current()).code as string;
                        const updated = await discussionsApi.addCommentToDiscussion({ discussionId, targetId, workspaceCode, addCommentToDiscussion: { comment } });
                        this.discussions = _parseDiscussions(updated);
                },
                async removeComment(discussionId: string, commentUuid: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current()).code as string;
                        const updated = await discussionsApi.removeCommentFromDiscussion({
                                workspaceCode,
                                targetId: this.targetId,
                                discussionId,
                                commentUuid
                        });
                        this.discussions = _parseDiscussions(updated);
                },
                async resolveDiscussion(discussionId: string, targetId: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current()).code as string;
                        const updated = await discussionsApi.resolveDiscussion({ discussionId, targetId, workspaceCode });
                        this.discussions = _parseDiscussions(updated);
                },
                async unresolveDiscussion(discussionId: string, targetId: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current()).code as string;
                        const updated = await discussionsApi.unresolveDiscussion({ discussionId, targetId, workspaceCode });
                        this.discussions = _parseDiscussions(updated);
                }
        }
});


export const useModelsApi = defineStore("api/models", {
        state: () => ({
                listingModels: [] as ListingModel[],
                models: [] as Model[],
                modelsLoaded: false
        }),
        getters: {
                getModel: ({ listingModels }) => (modelUuid: string) => listingModels.find(m => m.uuid === modelUuid),
                getModelDetails: ({ models }) => (modelUuid: string) => models.find(m => m.uuid === modelUuid)
        },
        actions: {
                async fetchWorkspaceModels(force = false) {
                        if (this.modelsLoaded && !force) return;
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        // This can take several seconds because of the variables -> dimensions -> expressions relation
                        this.models = await modelsApi.fetchWorkspaceModels({ workspaceCode });
                        this.modelsLoaded = true;
                },
                async createModel(
                        name: string,
                        periodRange: [Date, Date],
                        isPublic: boolean,
                        contributors: Record<string, ModelContributorRoleEnum>,
                        folderUuid?: string
                ) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const newModel = await modelsApi.createModel({
                                workspaceCode,
                                createModelParams: {
                                        name,
                                        period: {
                                                start: jsDateToCorrectUtcDate(periodRange[0]),
                                                end: jsDateToCorrectUtcDate(periodRange[1]),
                                                grain: 'month'
                                        },
                                        groupUuid: folderUuid,
                                        contributors,
                                        isPublic
                                }
                        });
                        this.models = [...this.models, newModel];

                        const navApi = useNavApi();
                        navApi.models = [
                                ...navApi.models,
                                { name, uuid: newModel.uuid, groupUuid: newModel.groupUuid }
                        ];

                        return newModel;
                },
                async duplicateModel(modelUuid: string, name: string, versionUuid: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const newModel = await modelsApi.duplicateModel({
                                workspaceCode,
                                modelUuid,
                                duplicateModelParams: { name, originalVersionUuid: versionUuid }
                        });
                        this.models = [...this.models, newModel];

                        const navApi = useNavApi();
                        navApi.models = [
                                ...navApi.models,
                                { name, uuid: newModel.uuid, groupUuid: newModel.groupUuid }
                        ];

                        return newModel;
                },
                async fetchModelDetails(modelUuid: string): Promise<Model> {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const model = await modelsApi.fetchModelDetails({ modelUuid, workspaceCode });
                        this.models = this.models.some(m => m.uuid === modelUuid) ?
                                this.models.map((m: Model) => m.uuid === modelUuid ? model : m) : [...this.models, model];
                        return model;
                },
                async saveVersionChanges(modelUuid: string, versionUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current())?.code as string;
                        const updatedModel = await modelsApi.saveVersionChanges({
                                workspaceCode,
                                modelUuid,
                                parentVersionUuid: versionUuid
                        });
                        this.models = _.cloneDeep(this.models).map((m: Model) => m.uuid === modelUuid ? updatedModel : m);
                },
                async updateModelDetails(modelUuid: string, params: UpdateModelParams) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        await modelsApi.updateModelProperties({ modelUuid, workspaceCode, updateModelParams: params });
                        this.models = this.models.map(m => m.uuid === modelUuid ? { ...m, ...params } : m);

                        const navApi = useNavApi();
                        navApi.models = navApi.models.map(m => m.uuid === modelUuid ? { ...m, ...params } : m);
                },
                async updateModelDimensionValues(modelUuid: string, dimensionUuid: string, dimensionValues: DimensionValue[]) {
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await modelsApi.updateModelDimensionValues({
                                workspaceCode,
                                dimensionUuid,
                                modelUuid,
                                updateModelDimensionValuesParams: { dimensionValues }
                        })
                },
                async updateModelPeriod(modelUuid: string, params: UpdateModelPeriodParams) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;

                        // XXX This offset is necessary to avoid day changes on the sent Date string
                        const correctParams = {
                                ...params,
                                period: {
                                        start: jsDateToCorrectUtcDate(params.period.start as Date),
                                        end: jsDateToCorrectUtcDate(params.period.end as Date)
                                }
                        };
                        // /XXX

                        const updatedModel = await modelsApi.updateModelPeriod({ workspaceCode, modelUuid, updateModelPeriodParams: correctParams });
                        this.models = _.cloneDeep(this.models).map((m: Model) => m.uuid === modelUuid ? updatedModel : m);
                },
                async updateModelPeriodGrain(modelUuid: string, params: UpdateModelPeriodGrainParams) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const updatedModel = await modelsApi.updateModelPeriodGrain({ workspaceCode, modelUuid, updateModelPeriodGrainParams: params });
                        this.models = _.cloneDeep(this.models).map((m: Model) => m.uuid === modelUuid ? updatedModel : m);
                },
                async updateModelContributors(modelUuid: string, contributors: Record<string, ModelContributorRoleEnum>) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;

                        const updatedModel = await modelsApi.updateModelContributors({
                                workspaceCode,
                                modelUuid,
                                updateModelContributorsParams: { contributors }
                        });

                        this.models = _.cloneDeep(this.models).map((m: Model) => m.uuid === modelUuid ? updatedModel : m);
                },
                async deleteModel(modelUuid: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        await modelsApi.deleteModel({ workspaceCode, modelUuid });

                        const navApi = useNavApi();
                        navApi.models = navApi.models.filter(m => m.uuid !== modelUuid);
                },
                async fetchModelTableData(modelUuid: string, versionUuid: string, scenarioUuid: string, refresh = false, snapshotUuid?: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const data = await modelsApi.fetchModelTableData({
                                workspaceCode,
                                modelUuid,
                                versionUuid,
                                scenarioUuid,
                                refresh,
                                snapshotUuid
                        });
                        return data;
                },
                async addVariable(modelUuid: string, variableName: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const newVariable = await modelsApi.addVariableToModel({
                                workspaceCode,
                                modelUuid: modelUuid,
                                addVariableToModelParams: {
                                        variableName
                                }
                        });
                        this.models = this.models.map(m =>
                                m.uuid === modelUuid ? { ...m, variables: [...m.variables, newVariable] } : m
                        );
                        return newVariable;
                },
                async updateVariable(modelUuid: string, variableUuid: string, params: UpdateModelVariableParams) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        await modelsApi.updateModelVariable({
                                modelUuid,
                                variableUuid,
                                workspaceCode,
                                updateModelVariableParams: params
                        });

                        this.models = this.models.map(m =>
                                m.uuid === modelUuid ?
                                        {
                                                ...m,
                                                variables: m.variables.map((v: Variable) =>
                                                        v.uuid === variableUuid ?
                                                                {
                                                                        ...v,
                                                                        ...params,
                                                                        dimAggregation: params.aggregateFunction === null ? null : params.aggregateFunction || v.dimAggregation,
                                                                        timeAggregation: params.timeAggregateFunction === null ? null : params.aggregateFunction || v.timeAggregation
                                                                } : v
                                                )
                                        } : m
                        );
                },
                async removeVariable(modelUuid: string, variableUuid: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        await modelsApi.deleteModelVariable({
                                workspaceCode,
                                modelUuid,
                                variableUuid
                        });
                        this.models = this.models.map(m =>
                                m.uuid === modelUuid ? { ...m, variables: m.variables.filter(v => v.uuid !== variableUuid) } : m
                        );
                },
                async addDimensionToVariable(modelUuid: string, variableUuid: string, dimensionUuid: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const updatedModel = await modelsApi.addDimensionToVariable({ workspaceCode, modelUuid, variableUuid, dimensionUuid });
                        this.models = this.models.map(m => m.uuid === modelUuid ? updatedModel : m);
                },
                async removeDimensionFromVariable(modelUuid: string, variableUuid: string, dimensionUuid: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const updatedModel = await modelsApi.removeDimensionFromVariable({ workspaceCode, modelUuid, variableUuid, dimensionUuid });
                        this.models = this.models.map(m => m.uuid === modelUuid ? updatedModel : m);
                },
                async addVariableDimensionValue(modelUuid: string, variableUuid: string, dimensionUuid: string, value: string, dimensionValuesPath: Record<string, string>) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const updatedVar = await modelsApi.addVariableDimensionValue(
                                {
                                        workspaceCode,
                                        modelUuid,
                                        variableUuid,
                                        dimensionUuid,
                                        updateModelVariableDimensionValueParams: {
                                                dimensionValuesPath,
                                                value
                                        }
                                }
                        )
                        this.models = this.models.map(m => m.uuid === modelUuid ?
                                {
                                        ...m,
                                        variables: m.variables.map(v => v.uuid === variableUuid ? updatedVar : v)
                                }
                                : m
                        )
                },
                // XXX Temporary, until we fix the crossjoin issue properly
                async updateVariableDimensionValue(modelUuid: string, variableUuid: string, dimensionUuid: string, value: string, dimensionValuesPath: Record<string, string>) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const updatedVar = await modelsApi.updateVariableDimensionValue(
                                {
                                        workspaceCode,
                                        modelUuid,
                                        variableUuid,
                                        dimensionUuid,
                                        updateModelVariableDimensionValueParams: {
                                                value,
                                                dimensionValuesPath
                                        }
                                }
                        )
                        this.models = this.models.map(m => m.uuid === modelUuid ?
                                {
                                        ...m,
                                        variables: m.variables.map(v => v.uuid === variableUuid ? updatedVar : v)
                                }
                                : m
                        )
                },
                async removeVariableDimensionValue(modelUuid: string, variableUuid: string, dimensionUuid: string, dimensionValuesPath: Record<string, string>) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const updatedVar = await modelsApi.removeVariableDimensionValue(
                                {
                                        workspaceCode,
                                        modelUuid,
                                        variableUuid,
                                        dimensionUuid,
                                        removeModelVariableDimensionValueParams: {
                                                dimensionValuesPath
                                        }
                                }
                        )
                        this.models = this.models.map(m => m.uuid === modelUuid ?
                                {
                                        ...m,
                                        variables: m.variables.map(v => v.uuid === variableUuid ? updatedVar : v)
                                }
                                : m
                        )
                },
                // / XXX
                async addExpression(modelUuid: string, variableUuid: string, params: AddExpressionToVariableParams) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;

                        // XXX This is necessary to avoid day changes on the sent Date string
                        const correctParams: AddExpressionToVariableParams = {
                                ...params,
                                periodStart: jsDateToCorrectUtcDate(params.periodStart),
                                periodEnd: jsDateToCorrectUtcDate(params.periodEnd)
                        };
                        // XXX
                        const updatedModel = await modelsApi.addExpressionToVariable({
                                workspaceCode,
                                modelUuid,
                                variableUuid,
                                addExpressionToVariableParams: correctParams
                        });
                        this.models = _.cloneDeep(this.models).map((m: Model) => m.uuid === modelUuid ? updatedModel : m);
                },
                async deleteExpression(modelUuid: string, variableUuid: string, expressionUuid: string) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const updated = await modelsApi.deleteExpression({ workspaceCode, modelUuid, variableUuid, expressionUuid });
                        this.models = _.cloneDeep(this.models).map((m: Model) => m.uuid === modelUuid ? updated : m);
                },
                async updateExpressionPeriodEnd(modelUuid: string, variableUuid: string, expressionUuid: string, periodEnd: Date) {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        const correctedPeriod = jsDateToCorrectUtcDate(periodEnd);
                        const updatedModel = await modelsApi.updateExpressionPeriodEnd({
                                workspaceCode,
                                modelUuid,
                                variableUuid,
                                expressionUuid,
                                updateVariableExpressionPeriodParams: { periodEnd: correctedPeriod }
                        });
                        this.models = _.cloneDeep(this.models).map((m: Model) => m.uuid === modelUuid ? updatedModel : m);
                },
                async validateExpressionFormula(
                        modelUuid: string,
                        variableUuid: string,
                        formula: string,
                        versionUuid: string,
                        scenarioUuid: string,
                        dimensionUuid: string,
                        periodStart: Date,
                        periodEnd: Date,
                        expressionUuid?: string
                ): Promise<string> {
                        const api = useWorkspaceApi();
                        const workspaceCode = (await api.current())?.code as string;
                        return await modelsApi.validateExpression({
                                modelUuid,
                                workspaceCode,
                                variableUuid,
                                validateExpressionParams: {
                                        formula,
                                        expressionUuid,
                                        scenarioUuid,
                                        dimensionUuid,
                                        versionUuid,
                                        periodStart: jsDateToCorrectUtcDate(periodStart),
                                        periodEnd: jsDateToCorrectUtcDate(periodEnd)
                                }
                        });
                },
                async changeModelFolder(modelUuid: string, newFolderUuid: string) {
                        const navApi = useNavApi();
                        const workspaceCode = (await useWorkspaceApi().current()).code as string;
                        await modelsApi.updateModelProperties({
                                modelUuid,
                                workspaceCode,
                                updateModelParams: {
                                        groupUuid: newFolderUuid === 'uncategorized' ? undefined : newFolderUuid
                                }
                        });

                        this.models = [...this.models].map(r =>
                                r.uuid === modelUuid ? { ...r, groupUuid: newFolderUuid } : r
                        );
                        navApi.models = [...navApi.models].map(r =>
                                r.uuid === modelUuid ? { ...r, groupUuid: newFolderUuid } : r
                        );
                },
                async createModelVersion(modelUuid: string, name: string, versionUuid?: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const newVersion = await modelsApi.createWorkspaceModelsVersion({
                                workspaceCode,
                                modelUuid,
                                createModelVersionParams: {
                                        name,
                                        oldVersionUuid: versionUuid
                                }
                        });

                        this.models = this.models.map(
                                (m: Model) => m.uuid === modelUuid ? { ...m, versions: [...m.versions, newVersion] } : m
                        );
                        return newVersion;
                },
                async deleteModelsVersion(modelUuid: string, versionUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const updatedModel = await modelsApi.deleteModelsVersion({ workspaceCode, modelUuid, versionUuid });
                        this.models = this.models.map(
                                (m: Model) => m.uuid === modelUuid ? updatedModel : m
                        );
                },
                async updateModelVersion(modelUuid: string, versionUuid: string, params: UpdateModelVersionParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        await modelsApi.updateModelVersion({
                                workspaceCode,
                                modelUuid,
                                versionUuid,
                                updateModelVersionParams: params
                        });
                        this.models = this.models.map(
                                (m: Model) =>
                                        m.uuid === modelUuid ?
                                                { ...m, versions: m.versions.map(v => v.uuid === versionUuid ? { ...v, ...params } : v) }
                                                : m
                        );
                },
                async updateModelVersionContributors(modelUuid: string, versionUuid: string, params: UpdateModelVersionContributorsParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const updatedModel = await modelsApi.updateModelVersionContributors({
                                workspaceCode,
                                modelUuid,
                                versionUuid,
                                updateModelVersionContributorsParams: params
                        });
                        this.models = this.models.map(
                                (m: Model) => m.uuid === modelUuid ? updatedModel : m
                        );
                },
                async publishModelVersion(modelUuid: string, versionUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const token = await modelsApi.publishModelVersion({ workspaceCode, modelUuid, versionUuid });
                        this.models = this.models.map(
                                (m: Model) => m.uuid === modelUuid ?
                                        { ...m, versions: m.versions.map(v => v.uuid === versionUuid ? { ...v, publicToken: token } : v) }
                                        : m
                        );
                        return token;
                },
                async unpublishModelVersion(modelUuid: string, versionUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const token = await modelsApi.unpublishModelVersion({ workspaceCode, modelUuid, versionUuid });
                        this.models = this.models.map(
                                (m: Model) => m.uuid === modelUuid ?
                                        { ...m, versions: m.versions.map(v => v.uuid === versionUuid ? { ...v, publicToken: undefined } : v) }
                                        : m
                        );
                        return token;
                },
                async fetchPublishedVersionData(token: string) {
                        const data = await publicApi.fetchPublishedModelTableData({ token });
                        return data;
                },
                async downloadModelVersionExcel(
                        modelUuid: string,
                        versionUuid: string,
                        downloadURL: string,
                        params: NotifyModelVersionExcelDownloadParams
                ) {
                        fetch(downloadURL, { method: 'GET' })
                                .then((response) => {
                                        response.blob()
                                                .then((blob) => {
                                                        useDownload(blob, `${modelUuid}-${versionUuid}.xlsx`);
                                                        publicApi.notifyModelVersionExcelDownload({
                                                                modelUuid,
                                                                versionUuid,
                                                                notifyModelVersionExcelDownloadParams: params
                                                        });
                                                });
                                })
                                .catch((err) => {
                                        console.error(err);
                                }
                                );
                },
                async fetchModelVariableDrillDown(params: ExtendedVariableDrillDownParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const data = await modelsApi.computeVariableDrillDown({
                                workspaceCode,
                                variableUuid: params.variableUuid,
                                variableDrillDownParams: {
                                        period: params.period,
                                        versionUuid: params.versionUuid,
                                        dimensionValues: params.dimensionValues,
                                        scenarioUuid: params.scenarioUuid
                                } as VariableDrillDownParams
                        });
                        return data;
                },
                async setFavorite(modelUuid: string, state: boolean) {
                        const worspaceCode = (await useWorkspaceApi().current()).code;
                        const nav = useNavApi();
                        const model = this.getModelDetails(modelUuid) as Model;
                        await modelsApi.setModelFavoriteStatus({ workspaceCode: worspaceCode, cofiApiRoutersModelsFavoriteParams: { modelUuid, state } });
                        nav.favoriteModels = state ? [...nav.favoriteModels, { ...model } as NavSection] : nav.favoriteModels.filter(m => m.uuid !== modelUuid);
                }
        }
});

export interface ExtendedVariableDrillDownParams extends VariableDrillDownParams {
        versionUuid: string
        modelUuid: string
        variableUuid: string
        dimensionValues?: Record<string, string>
}

export const useAIApi = defineStore("api/ai", {
        state: () => ({
                chat: null as null | ReturnType<typeof useChat>,
        }),
        actions: {
                async startConversation(context: string, entityUuid?: string, extraData?: Record<string, string>) {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const conversationUuid = await aiApi.startConversation({ workspaceCode, startConversationParams: {context, entityUuid, extraData} });
                        this.chat = useChat(conversationUuid);
                        await this.chat.startConversation();
                },
                async regenerateAnswer(): Promise<string> {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const response = await aiApi.regenerateLastAnswer({ workspaceCode, conversationUuid: this.chat.conversationUuid });
                        this.chat.conversationUuid = response.conversationUuid;
                        return response.answer;
                }
        }
});


export const useCommunityApi = defineStore("api/community", {
        state: () => ({
                assets: [] as CommunityAsset[],
                modelsDimensions: {} as Record<string, Dimension[]>,
                modelsScenarios: {} as Record<string, ScenarioWithDate[]>
        }),
        getters: {
                getAssetDetails: ({ assets }) => (assetUuid: string) => assets.find(a => a.uuid === assetUuid)
        },
        actions: {
                async fetchAssets(tags?: string[]) {
                        const assets = await communityApi.fetchCommunityAssets({ tags });
                        const newAssets = assets.filter(a => !this.assets.some(aa => aa.uuid === a.uuid));
                        this.assets = [
                                ...this.assets,
                                ...newAssets
                        ];
                },
                async fetchAssetDetails(assetUuid: string): Promise<CommunityAsset | undefined> {
                        const asset = await communityApi.fetchAssetDetails({ assetUuid });
                        if (asset) {
                                if (this.assets.some(a => a.uuid === assetUuid))
                                        this.assets = this.assets.map(a => a.uuid === assetUuid ? asset : a);
                                else
                                        this.assets = [...this.assets, asset];
                                return asset;
                        }
                },
                async updateAssetDetails(assetUuid: string, params: UpdateAssetDetailsParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        await communityApi.updateAssetDetails({ workspaceCode, assetUuid, updateAssetDetailsParams: params });
                        this.assets = this.assets.map(a => a.uuid === assetUuid ? { ...a, ...params } : a);
                },
                async unpublishAsset(assetUuid: string) {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        await communityApi.unpublishCommunityAsset({ workspaceCode, assetUuid });
                        this.assets = this.assets.filter(a => a.uuid !== assetUuid);
                },
                async publishModelAsset(modelUuid: string, params: PublishModelToCommunityParams) {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const asset = await communityApi.publishModelToCommunity({
                                workspaceCode,
                                modelUuid,
                                publishModelToCommunityParams: params
                        });

                        this.assets = [...this.assets, asset];
                        const modelsApi = useModelsApi();
                        modelsApi.models = modelsApi.models.map(m => m.uuid === modelUuid ? { ...m, communityPublishedAssetUuid: asset.uuid } : m);
                        return asset;
                },
                async fetchModelPreviewData(modelUuid: string, versionUuid: string, scenarioUuid?: string) {
                        const data = await communityApi.fetchModelPreviewData({ modelUuid, versionUuid, scenarioUuid });
                        if (data.dimensions)
                                this.modelsDimensions[modelUuid] = data.dimensions;
                        if (data.scenarios)
                                this.modelsScenarios[modelUuid] = data.scenarios;
                        return data;
                },
                async importModelToWorkspace(
                        modelUuid: string,
                        targetWorkspaceUuid: string,
                        dimensionsMap?: Record<string, string>,
                        scenariosMap?: Record<string, string>
                ) {
                        const newModel = await communityApi.importModelToWorkspace({
                                modelUuid,
                                importModelParams: {
                                        targetWorkspaceUuid,
                                        dimensionsMap,
                                        scenariosMap
                                }
                        });
                        return newModel;
                }
        }
});

export interface DatabaseData {
        table: string[][];
        columns: string[];
}

export const useDatabasesApi = defineStore("api/databases", {
        state: () => ({
                databases: [] as Database[],
                databaseData: {} as Record<string, DatabaseData>,
                statuses: {} as Record<string, Status>
        }),
        getters: {
                getDatabase: ({ databases }) => (databaseUuid: string): Database => databases.find(d => d.uuid === databaseUuid),
                getDatabaseData: ({ databaseData }) => (databaseUuid: string) => databaseData[databaseUuid],
                getStatus: ({ statuses }) => (databaseUuid: string): Status => statuses[databaseUuid]
        },
        actions: {
                async fetchDatabases(force = false): Promise<void> {
                        if (this.databases.length && !force) return;
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const databases = await databasesApi.workspaceDatabses({ workspaceCode });
                        this.databases = databases;
                        this.statuses = _.fromPairs(databases.map((d: Database) => [d.uuid, d.status]));
                },
                async createDatabase({ name, description, code, groupUuid, sourceUuid, sourceType }: { name: string, description?: string, code?: string, groupUuid?: string, sourceUuid?: string, sourceType?: CreateDatabaseParamsSourceTypeEnum }): Promise<Database> {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const navApi = useNavApi();
                        const database = await databasesApi.createDatabase({ workspaceCode, createDatabaseParams: { name, description, code, groupUuid, sourceUuid, sourceType } });
                        const dsApi = useDatasourcesApi();
                        this.databases = [...this.databases, database];
                        navApi.databases = [...navApi.databases, { name: database.name, uuid: database.uuid, groupUuid: database.groupUuid }];
                        dsApi._combineDatasources();
                        this.fetchDatabaseStatus(database.uuid);
                        return database;
                },
                async updateDatabase(databaseUuid: string, params: UpdateDatabaseParams): Promise<Database> {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const navApi = useNavApi();
                        const database = await databasesApi.updateDatabase({ workspaceCode, databaseUuid, updateDatabaseParams: params });
                        this.databases = this.databases.map(d => d.uuid === databaseUuid ? database : d);
                        navApi.databases = navApi.databases.map(
                                d => d.uuid === databaseUuid ? { name: database.name, uuid: database.uuid, groupUuid: database.groupUuid } : d
                        );
                        return database;
                },
                async deleteDatabase(databaseUuid: string): Promise<void> {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const navApi = useNavApi();
                        await databasesApi.deleteDatabase({ workspaceCode, databaseUuid });
                        this.databases = this.databases.filter((d: Database) => d.uuid !== databaseUuid);
                        navApi.databases = navApi.databases.filter((d: Database) => d.uuid !== databaseUuid);
                },
                async fetchDatabaseData(databaseUuid: string, params?: FetchDatabaseParams): Promise<void> {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const data = await databasesApi.databaseDataframe({ workspaceCode, databaseUuid, fetchDatabaseParams: params });
                        const database = this.getDatabase(databaseUuid) as Database;
                        const datasource = {
                                name: database?.name,
                                uuid: database?.uuid,
                                groupUuid: database?.groupUuid,
                                description: database?.description,
                                code: database?.code,
                                type: "database",
                                isPublic: database?.isPublic,
                                schema: database.schema,
                                updatedAt: database?.updatedAt,
                                favorited: false
                        } as Datasource;
                        this.databaseData[databaseUuid] = useDatasourcesApi()._parseOverviewTable(datasource, data);
                },
                async updateDatabaseSchema(databaseUuid: string, params: UpdateDatabaseSchemaParams): Promise<void> {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        await databasesApi.updateDatabaseSchema({ workspaceCode, databaseUuid, updateDatabaseSchemaParams: params });
                        this.databases = this.databases.map((d: Database) => d.uuid === databaseUuid ? { ...d, schema: params.customSchema } : d);
                },
                async updateDatabaseRelationships(databaseUuid: string, params: UpdateDatabaseRelationshipsParams): Promise<void> {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const database = await databasesApi.updateDatabaseRelationships({ workspaceCode, databaseUuid, updateDatabaseRelationshipsParams: params });
                        this.databases = this.databases.map((d: Database) => d.uuid === databaseUuid ? database : d);
                },
                async fetchDatabaseStatus(databaseUuid: string): Promise<void> {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const status = await databasesApi.fetchDatabaseStatus({ workspaceCode, databaseUuid });
                        this.statuses[databaseUuid] = status as Status;
                },
                async processDatabase(databaseUuid: string): Promise<void> {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        await databasesApi.processDatabase({ workspaceCode, databaseUuid });
                },
                async updateDatabaseOwners(databaseUuid: string, params: UpdateDatabaseOwnersParams): Promise<void> {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        const database = await databasesApi.updateDatabaseOwners({ workspaceCode, databaseUuid, updateDatabaseOwnersParams: params });
                        this.databases = this.databases.map((d: Database) => d.uuid === databaseUuid ? database : d);
                }
        }
});

export const useAdminApi = defineStore("api/admin", {
        actions: {
                async debugMetric(metricUuid: string, scenarioUuid: string) {
                        return await adminApi.debugMetricQuerySources({ metricUuid, debugMetricParams: { scenarioUuid } });
                },
                async computeQuery(query: string) {
                        return await adminApi.debugComputeQuery({ serializedQuery: { query } as SerializedQuery });
                }
        }
});

export const useDagApi = defineStore("api/dag", {
        actions: {
                async getEntityAffectedNodes(entityUuid: string): Promise<Array<string, Record<string, any>>[]> {
                        const workspaceCode = (await useWorkspaceApi().current()).code;
                        return await dagApi.getAffectedEntities({ workspaceCode, entityUuid });
                }
        }
});

export const useSnapshotsApi = defineStore("api/snapshots", {
        state: () => ({
                snapshots: [] as Snapshot[]
        }),
        actions: {
                async fetchWorkspaceSnapshots(force = false): Promise<void> {
                        if (!force && !!this.snapshots.length) return;
                        const workspaceCode = useWorkspaceApi()._current?.code!;
                        const snapshots = await snapshotsApi.workspaceSnapshots({ workspaceCode });
                        this.snapshots = [...snapshots];
                },
                async createSnapshot(name: string): Promise<void> {
                        const workspaceCode = useWorkspaceApi()._current?.code!;
                        const snapshot = await snapshotsApi.createSnapshot({ workspaceCode, createSnapshotParams: { name } });
                        this.snapshots = [...this.snapshots, snapshot];
                },
                async updateSnapshot(snapshotUuid: string, name: string): Promise<void> {
                        const workspaceCode = useWorkspaceApi()._current?.code!;
                        const snapshot = await snapshotsApi.createSnapshot({ workspaceCode, createSnapshotParams: { name } });
                        this.snapshots = this.snapshots.map((sn: Snapshot) => sn.uuid === snapshotUuid ? snapshot : sn);
                },
                async deleteSnapshot(snapshotUuid: string): Promise<void> {
                        const workspaceCode = useWorkspaceApi()._current?.code!;
                        await snapshotsApi.deleteSnapshot({ workspaceCode, snapshotUuid });
                        this.snapshots = this.snapshots.filter((sn: Snapshot) => sn.uuid !== snapshotUuid);
                },
                async setSnapshotOwners(snapshotUuid: string, owners: SnapshotOwner[]): Promise<void> {
                        const workspaceCode = useWorkspaceApi()._current?.code;
                        const ownersMap = owners.reduce((acc, o: SnapshotOwner) => ({ ...acc, [o.userUuid]: o.role }), {});
                        const snapshot = await snapshotsApi.setSnapshotOwners({ workspaceCode, snapshotUuid, setSnapshotOwnersParams: { owners: ownersMap } });
                        this.snapshots = this.snapshots.map((sn: Snapshot) => sn.uuid === snapshotUuid ? snapshot : sn);
                }
        }
});


function findTableJsonInDoc(node: any, tableId: string) {
        let queue = [node];
        let curr;
        while (queue.length) {
                curr = queue.pop();
                if (curr['attrs']?.['tableId'] === tableId) return curr;
                if (curr['content']?.length) {
                        queue = queue.concat(curr['content']);
                }
        }
        return undefined;
}

export function tableListenToChanges(
        // TODO: More granular fetches using these
        tableUuid: string,
        selectedMetrics: Ref<(string) | (string[])>,
        selectedScenarios: Ref<(string) | (string[])>,
        // / TODO
        tableFetchFn: (refresh: boolean) => Promise<void>
): void {
        const metricsApi = useMetricsApi();
        const dashboardsApi = useDashboardsApi();

        const unwatch = watch(() => metricsApi.recomputing, (val, oldVal) => {
                const recomputingBefore = oldVal;
                const recomputingAfter = val;
                if (recomputingBefore && !recomputingAfter) {
                        delete dashboardsApi.tableValues[tableUuid]
                        tableFetchFn(false);
                        unwatch(); // remove the watcher after first trigger
                }
        });

        const unsubscribe = dashboardsApi.$onAction(async ({ name, after }) => {
                after(async () => {
                        if (name == "updateDashboardFilters") {
                                await tableFetchFn(true);
                        }
                        if (name == "setDashboardSnapshot") {
                                await tableFetchFn(true)
                        }
                        if (name === "setTabSelectedValues") {
                                await tableFetchFn(false)
                        }
                });
        });
        onUnmounted(() => {
                unwatch();
                unsubscribe();
        });
}


export function resetDataStores() {
        useNavApi().$reset();
        useDagApi().$reset();
        useModelsApi().$reset();
        useInviteApi().$reset();
        useMetricsApi().$reset();
        useDashboardsApi().$reset();
        useDatabasesApi().$reset();
        useScenariosApi().$reset();
        useCommunityApi().$reset();
        useUploadersApi().$reset();
        useConnectorsApi().$reset();
        useDimensionsApi().$reset();
        useDatasourcesApi().$reset();
        useAIApi().$reset();
}
