/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddDimensionValuesParams,
  CreateDimensionParams,
  CreateMappingParams,
  Dimension,
  DimensionValue,
  HTTPValidationError,
  Mapping,
  SetSelectedValueParams,
  UpdateDimensionValuesParams,
  UpdateMappingParams,
} from '../models/index';
import {
    AddDimensionValuesParamsFromJSON,
    AddDimensionValuesParamsToJSON,
    CreateDimensionParamsFromJSON,
    CreateDimensionParamsToJSON,
    CreateMappingParamsFromJSON,
    CreateMappingParamsToJSON,
    DimensionFromJSON,
    DimensionToJSON,
    DimensionValueFromJSON,
    DimensionValueToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    MappingFromJSON,
    MappingToJSON,
    SetSelectedValueParamsFromJSON,
    SetSelectedValueParamsToJSON,
    UpdateDimensionValuesParamsFromJSON,
    UpdateDimensionValuesParamsToJSON,
    UpdateMappingParamsFromJSON,
    UpdateMappingParamsToJSON,
} from '../models/index';

export interface AddDimensionValueRequest {
    dimensionUuid: string;
    workspaceCode: string;
    addDimensionValuesParams: AddDimensionValuesParams;
}

export interface CreateDimensionRequest {
    workspaceCode: string;
    createDimensionParams: CreateDimensionParams;
}

export interface CreateMappingRequest {
    workspaceCode: string;
    createMappingParams: CreateMappingParams;
}

export interface DeleteDimensionRequest {
    dimensionUuid: string;
    workspaceCode: string;
}

export interface DeleteDimensionValueRequest {
    dimensionValueUuid: string;
    workspaceCode: string;
}

export interface DeleteMappingRequest {
    mappingUuid: string;
    workspaceCode: string;
}

export interface FuzzySearchDimValuesRequest {
    dimensionUuid: string;
    workspaceCode: string;
    q?: string;
    entityUuid?: string;
}

export interface ListDimensionValuesRequest {
    dimensionUuid: string;
    workspaceCode: string;
}

export interface ListWorkspaceDimensionsRequest {
    workspaceCode: string;
}

export interface ListWorkspaceMappingsRequest {
    workspaceCode: string;
}

export interface SetDimensionDefaultValueRequest {
    workspaceCode: string;
    dimensionUuid: string;
    setSelectedValueParams: SetSelectedValueParams;
}

export interface UpdateDimensionValueRequest {
    dimensionValueUuid: string;
    workspaceCode: string;
    updateDimensionValuesParams: UpdateDimensionValuesParams;
}

export interface UpdateMappingRequest {
    workspaceCode: string;
    updateMappingParams: UpdateMappingParams;
}

/**
 * 
 */
export class DimensionsApi extends runtime.BaseAPI {

    /**
     * Add Dimension Value
     */
    async addDimensionValueRaw(requestParameters: AddDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['dimensionUuid'] == null) {
            throw new runtime.RequiredError(
                'dimensionUuid',
                'Required parameter "dimensionUuid" was null or undefined when calling addDimensionValue().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling addDimensionValue().'
            );
        }

        if (requestParameters['addDimensionValuesParams'] == null) {
            throw new runtime.RequiredError(
                'addDimensionValuesParams',
                'Required parameter "addDimensionValuesParams" was null or undefined when calling addDimensionValue().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions/{dimension_uuid}/values`.replace(`{${"dimension_uuid"}}`, encodeURIComponent(String(requestParameters['dimensionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddDimensionValuesParamsToJSON(requestParameters['addDimensionValuesParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add Dimension Value
     */
    async addDimensionValue(requestParameters: AddDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.addDimensionValueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Dimension
     */
    async createDimensionRaw(requestParameters: CreateDimensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Dimension>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createDimension().'
            );
        }

        if (requestParameters['createDimensionParams'] == null) {
            throw new runtime.RequiredError(
                'createDimensionParams',
                'Required parameter "createDimensionParams" was null or undefined when calling createDimension().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDimensionParamsToJSON(requestParameters['createDimensionParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DimensionFromJSON(jsonValue));
    }

    /**
     * Create Dimension
     */
    async createDimension(requestParameters: CreateDimensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Dimension> {
        const response = await this.createDimensionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Mapping
     */
    async createMappingRaw(requestParameters: CreateMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Mapping>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createMapping().'
            );
        }

        if (requestParameters['createMappingParams'] == null) {
            throw new runtime.RequiredError(
                'createMappingParams',
                'Required parameter "createMappingParams" was null or undefined when calling createMapping().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions/mapping`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMappingParamsToJSON(requestParameters['createMappingParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MappingFromJSON(jsonValue));
    }

    /**
     * Create Mapping
     */
    async createMapping(requestParameters: CreateMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Mapping> {
        const response = await this.createMappingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Dimension
     */
    async deleteDimensionRaw(requestParameters: DeleteDimensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['dimensionUuid'] == null) {
            throw new runtime.RequiredError(
                'dimensionUuid',
                'Required parameter "dimensionUuid" was null or undefined when calling deleteDimension().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteDimension().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions/{dimension_uuid}/delete`.replace(`{${"dimension_uuid"}}`, encodeURIComponent(String(requestParameters['dimensionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Dimension
     */
    async deleteDimension(requestParameters: DeleteDimensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteDimensionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Dimension Value
     */
    async deleteDimensionValueRaw(requestParameters: DeleteDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['dimensionValueUuid'] == null) {
            throw new runtime.RequiredError(
                'dimensionValueUuid',
                'Required parameter "dimensionValueUuid" was null or undefined when calling deleteDimensionValue().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteDimensionValue().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions/{dimension_value_uuid}/values`.replace(`{${"dimension_value_uuid"}}`, encodeURIComponent(String(requestParameters['dimensionValueUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Dimension Value
     */
    async deleteDimensionValue(requestParameters: DeleteDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteDimensionValueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Mapping
     */
    async deleteMappingRaw(requestParameters: DeleteMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['mappingUuid'] == null) {
            throw new runtime.RequiredError(
                'mappingUuid',
                'Required parameter "mappingUuid" was null or undefined when calling deleteMapping().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteMapping().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions/mapping/{mapping_uuid}`.replace(`{${"mapping_uuid"}}`, encodeURIComponent(String(requestParameters['mappingUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Mapping
     */
    async deleteMapping(requestParameters: DeleteMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteMappingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fuzzy Search Dimension Values
     */
    async fuzzySearchDimValuesRaw(requestParameters: FuzzySearchDimValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['dimensionUuid'] == null) {
            throw new runtime.RequiredError(
                'dimensionUuid',
                'Required parameter "dimensionUuid" was null or undefined when calling fuzzySearchDimValues().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fuzzySearchDimValues().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['entityUuid'] != null) {
            queryParameters['entity_uuid'] = requestParameters['entityUuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions/{dimension_uuid}/values_search`.replace(`{${"dimension_uuid"}}`, encodeURIComponent(String(requestParameters['dimensionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Fuzzy Search Dimension Values
     */
    async fuzzySearchDimValues(requestParameters: FuzzySearchDimValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.fuzzySearchDimValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Dimension Values
     */
    async listDimensionValuesRaw(requestParameters: ListDimensionValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DimensionValue>>> {
        if (requestParameters['dimensionUuid'] == null) {
            throw new runtime.RequiredError(
                'dimensionUuid',
                'Required parameter "dimensionUuid" was null or undefined when calling listDimensionValues().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling listDimensionValues().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions/{dimension_uuid}/values`.replace(`{${"dimension_uuid"}}`, encodeURIComponent(String(requestParameters['dimensionUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DimensionValueFromJSON));
    }

    /**
     * List Dimension Values
     */
    async listDimensionValues(requestParameters: ListDimensionValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DimensionValue>> {
        const response = await this.listDimensionValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Dimensions
     */
    async listWorkspaceDimensionsRaw(requestParameters: ListWorkspaceDimensionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Dimension>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling listWorkspaceDimensions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DimensionFromJSON));
    }

    /**
     * List Dimensions
     */
    async listWorkspaceDimensions(requestParameters: ListWorkspaceDimensionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Dimension>> {
        const response = await this.listWorkspaceDimensionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Mapings
     */
    async listWorkspaceMappingsRaw(requestParameters: ListWorkspaceMappingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Mapping>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling listWorkspaceMappings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions/mappings`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MappingFromJSON));
    }

    /**
     * List Mapings
     */
    async listWorkspaceMappings(requestParameters: ListWorkspaceMappingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Mapping>> {
        const response = await this.listWorkspaceMappingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Dimension Values
     */
    async setDimensionDefaultValueRaw(requestParameters: SetDimensionDefaultValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling setDimensionDefaultValue().'
            );
        }

        if (requestParameters['dimensionUuid'] == null) {
            throw new runtime.RequiredError(
                'dimensionUuid',
                'Required parameter "dimensionUuid" was null or undefined when calling setDimensionDefaultValue().'
            );
        }

        if (requestParameters['setSelectedValueParams'] == null) {
            throw new runtime.RequiredError(
                'setSelectedValueParams',
                'Required parameter "setSelectedValueParams" was null or undefined when calling setDimensionDefaultValue().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions/{dimension_uuid}/default`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"dimension_uuid"}}`, encodeURIComponent(String(requestParameters['dimensionUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetSelectedValueParamsToJSON(requestParameters['setSelectedValueParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Set Dimension Values
     */
    async setDimensionDefaultValue(requestParameters: SetDimensionDefaultValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.setDimensionDefaultValueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Dimension Value
     */
    async updateDimensionValueRaw(requestParameters: UpdateDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['dimensionValueUuid'] == null) {
            throw new runtime.RequiredError(
                'dimensionValueUuid',
                'Required parameter "dimensionValueUuid" was null or undefined when calling updateDimensionValue().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateDimensionValue().'
            );
        }

        if (requestParameters['updateDimensionValuesParams'] == null) {
            throw new runtime.RequiredError(
                'updateDimensionValuesParams',
                'Required parameter "updateDimensionValuesParams" was null or undefined when calling updateDimensionValue().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions/{dimension_value_uuid}/values`.replace(`{${"dimension_value_uuid"}}`, encodeURIComponent(String(requestParameters['dimensionValueUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDimensionValuesParamsToJSON(requestParameters['updateDimensionValuesParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Dimension Value
     */
    async updateDimensionValue(requestParameters: UpdateDimensionValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateDimensionValueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Mapping
     */
    async updateMappingRaw(requestParameters: UpdateMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Mapping>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateMapping().'
            );
        }

        if (requestParameters['updateMappingParams'] == null) {
            throw new runtime.RequiredError(
                'updateMappingParams',
                'Required parameter "updateMappingParams" was null or undefined when calling updateMapping().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dimensions/mapping`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMappingParamsToJSON(requestParameters['updateMappingParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MappingFromJSON(jsonValue));
    }

    /**
     * Update Mapping
     */
    async updateMapping(requestParameters: UpdateMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Mapping> {
        const response = await this.updateMappingRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
